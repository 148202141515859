/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Protected_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Protected_Data_API.js
 *	@author: Белов Михаил Александрович
*/
import {$host} from '../index';

//-----------

//===========
// begin [ Protected ]

export const fetchOneProtected_Data = async (id) => {
    if (!id)
        return null;

    const { data } = await $host.get('api/protected/' + encodeURIComponent(btoa(id)) + '/' + localStorage.getItem('token'));
    return data;
}

// end [ Protected ]
//===========

//-----------