import React from 'react';

import './ErrorBoundary.css';

const ErrorComponent = ({ error }) => {
    const HandleRedirect = () => {
        window.location.pathname = '/';
    };

    return <div className='error-page'>
        <div className='error-dialog'>
            <div className='error-icon'>x</div>
            <div className='error-title'>Ошибка</div>
            <div className='error-message'>{error ?? 'без описания'}</div>
            <button onClick={HandleRedirect} className='error-button'>ОК</button>
        </div>
    </div>;
};

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, info) {
        console.error(error);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorComponent error={this.state.error?.message} />;
        }

        return this.props.children;
    }
};