/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Gistology_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "гистология". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import '../../components/Categories_Page.css';
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';

export const Gistology_EntriesPerPage = 8;

const Gistology_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});
    const location = useLocation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page).then((data) => setPageData(data));
    }, [location, user, user.search]);

    if (!pageData || !pageData?.subCategory)
        return;

    return (
        <div className='page_content'>

            <div className='page_content_title'>
                <img className='page_title_icon' src='/icons/histology_icon.png' alt='page_title_icon'></img>{t("Histology_title")}
            </div>
            <div className='page_content_parent'>
                <div className='page_content_flex'>
                    <SidebarCategoriesDisplay categories={pageData.subCategory} matchingCategories={pageData.search?.matches} />
                </div>
                <div className='page_content_rightpage_block'>
                    <Search_Bar searchSucceeded={pageData.search?.succeeded} />
                    <div className='page_content_rightpage'>
                        <div className='page_content_title_info'>
                            <b>{t("Histology_title")}</b> - {t("Histology_title_1")}
                            <br></br>
                            <br></br>
                            {t("Histology_title_2")}
                            <br></br>
                            {t("Histology_title_3")}
                            <br></br>
                            {t("Histology_title_4")}
                            <br></br>
                            {t("Histology_title_5")}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
});

export default Gistology_Categories_Page;