/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Endoscopy_Data_Image.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "эндоскопия". В этом файле находится компонент, отвечающий за вывод картинки из списка картинок в кейсе.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import ImageProtected from "../ProtectedImage";

const Endoscopy_Data_Image = ({ url }) => {
    return <div className="case_image">
        <ImageProtected className='case_image_endoscopy' url={url} />
    </div>;
};

export default Endoscopy_Data_Image;