/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "экг". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import "./ECG_Categories_Page.css"
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';
import '../../components/Categories_Page.css';

export const ECG_EntriesPerPage = 6;

const ECG_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(sessionStorage.getItem('i18nextLng') || 'en');

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page).then((data) => setPageData(data));
    }, [location, user, user.search]);

    if (!pageData || !pageData?.subCategory)
        return;

    return <div className='page_content'>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/ekg_icon.png' alt='page_title_icon'></img>{t('ECG_title')}
        </div>
        <div className='page_content_parent'>
            <div className='page_content_flex'>
                <SidebarCategoriesDisplay categories={pageData.subCategory} matchingCategories={pageData.search?.matches} />
            </div>
            <div className='page_content_rightpage_block'>
                <div className='Search_bar_block'>
                    <Search_Bar searchSucceeded={pageData.search?.succeeded} />
                </div>
                <div className='page_content_rightpage'>
                    <div className='page_content_title_info'>{t('ECG_page_content_title_info')}</div>
                    <div className='ekg_main_pic1'>
                        <img src='/ECG/ekg_body_human.png'></img>
                        <div className='ekg_rightpage_pic_description'>
                            {t('ECG_ekg_rightpage_pic_description')}</div>
                    </div>
                    <div className='ekg_rightpage_info_22'>{t('ECG_ekg_rightpage_info_22')}
                        <br></br>
                        <br></br>
                        <div className='ekg_rightpage_tags'>
                            <div className='ekg_rightpage_flex'>
                                <br></br>
                                <br></br>• {t('ECG_ritm')}
                                <br></br>
                                <br></br>• {t('ECG_reboothealth')}
                                <br></br>
                                <br></br>• {t('ECG_ishemiya')}
                                <br></br>
                                <br></br>• {t('ECG_meta')}
                                <br></br>
                                <br></br>
                            </div>
                        </div>{t('ECG_reg')}
                    </div>
                    <div className='ekg_rightpage_info_title'>{t('ECG_electrods')}
                        <div className='ekg_main_pic1'>
                            <img src={t('ekg_main_pic2')}></img>
                        </div>
                    </div>
                    <div className='ekg_rightpage_info_22'>{t('ECG_12_otdeleny')}
                        <br></br>
                        <br></br>1. {t('ECG_desc1')}
                        <br></br>
                        <br></br>{t('ECG_desc2')}
                        <br></br>{t('ECG_desc3')}
                        <br></br>{t('ECG_desc4')}
                        <div className='ekg_main_pic1'>
                            <img src={t('ekg_main_pic3')}></img>
                        </div>
                        {t('ECG_desc5')}
                        <br></br>
                        <br></br>2. {t('ECG_desc6')}
                        <br></br>
                        <br></br>3. {t('ECG_desc7')}
                        <div className='ekg_main_pic1'>
                            <img src='/ECG/Рисунок5.png'></img>
                        </div>
                        <br></br>{t('ECG_desc8')}
                        <div className='ekg_main_pic1'>
                            <img src='/ECG/Рисунок6.png'></img>
                        </div>

                        <br></br>{t('ECG_desc9')}
                        <div className='ekg_main_container'>
                            <div className='ekg_main_pic1'>
                                <img src={t('ekg_main_pic7')} alt="EKG Image 7" />
                            </div>
                            <div className='ekg_main_pic1'>
                                <img src={t('ekg_main_pic8')} alt="EKG Image 8" />
                            </div>
                        </div>

                        <br></br>{t('ECG_desc10')}

                        <br></br>{t('ECG_desc11')}
                        <br></br>{t('ECG_desc12')}

                        <br></br>{t('ECG_desc13')}

                        <br></br>{t('ECG_desc14')}

                        <br></br>{t('ECG_desc15')}

                        <br></br>{t('ECG_desc16')}

                        <br></br>{t('ECG_desc17')}

                        <br></br>{t('ECG_desc18')}

                        <br></br>{t('ECG_desc19')}

                        <br></br>{t('ECG_desc20')}

                        <br></br>{t('ECG_desc21')}

                    </div>
                </div>
            </div>

        </div>
    </div>;
});

export default ECG_Categories_Page;