/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: SkinDisease_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. SkinDisease_Data_API.js
 *	@author: Белов Михаил Александрович
*/
import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ SkinDisease ]

//===========
// begin [ SkinDisease_Categories ]

export const fetchSkinDisease_Categories = async (search, language) => {
    const {data} = await $host.get('api/skindisease/skindisease_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOneSkinDisease_Categories = async (id) => {
    const {data} = await $host.get('api/skindisease/skindisease_categories/' + id)
    return data
}

// end [ SkinDisease_Categories ]
//===========

//-----------

//===========
// begin [ SkinDisease_Data ]

export const fetchSkinDisease_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/skindisease/skindisease_data', {params: {
            search, language, categories_id, page, limit
        }})
    return data
}

export const fetchOneSkinDisease_Data = async (id) => {
    const {data} = await $host.get('api/skindisease/skindisease_data/' + id)
    return data
}

// end [ SkinDisease_Data ]
//===========

//// end [ SkinDisease ]
////===========

//-----------