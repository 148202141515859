/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Support_guide.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Support Support_guide.js
 *	@author: Белов Михаил Александрович, Манжос Геннадий Юрьевич
*/
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "./Support_guide.css"
import i18n from 'i18next';
import { useTranslation } from "react-i18next";

const Support_guide_page = observer(() => {
    const history = useNavigate()
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState('Account'); // начальная страница

    useEffect(() => {
        if (window.location.hash)
            setCurrentPage(window.location.hash.substring(1));
        else
            setCurrentPage('Accout');
    }, []); // Пустой массив зависимостей означает, что эффект будет выполнен только при первом рендере

    const ChangeTab = (tab) => {
        setCurrentPage(tab);
        window.location.hash = tab;
    };

    return (
        <div className='support_guide_page '>
            <div className='support_guide_page_grid'>
                <div className='support_guide_header'>
                    <div className='support_guide_header_icon_and_title'>
                        <img className='grey_logo_pimu' src='icons/grey_logo_pimu.png'></img>
                        <div className='support_guide_header_title'>{t('support_guide_title')}</div>
                    </div>
                    <div className='support_guide_header_title2'>
                        {t('support_guide_title_desc')}
                    </div>
                </div>
                <div className='support_guide_sidebar'>
                    <button onClick={ e => ChangeTab('Account') } className={ `support_guide_sidebar_menu ${currentPage == 'Account' ? 'active' : ''}`}>
                        <img className='support_sidebar_icon' src='icons/support/support_guide/User_scan_duotone_line@2x.png'></img>
                        {t('support_acount')}
                    </button>
                    <button onClick={ e => ChangeTab('Data') } className={ `support_guide_sidebar_menu ${currentPage == 'Data' ? 'active' : ''}` }>
                        <img className='support_sidebar_icon' src='icons/support/support_guide/Chield_check_light@2x.png'></img>
                        {t('support_med_data')}
                    </button>
                    <button onClick={ e => ChangeTab('Help') } className={ `support_guide_sidebar_menu ${currentPage == 'Help' ? 'active' : ''}` }>
                        <img className='support_sidebar_icon' src='icons/support/support_guide/Mortarboard_alt_light@2x.png'></img>
                        {t('volonteers')}
                    </button>
                    <button onClick={ e => ChangeTab('Buy') } className={ `support_guide_sidebar_menu ${currentPage == 'Buy' ? 'active' : ''}` }>
                        <img className='support_sidebar_icon' src='icons/support/support_guide/Basket_alt_3_light@2x.png'></img>
                        {t('Demo')}
                    </button>
                </div>
                <div className='support_guide_main'>

                    <div className={`support_guide_main_selected ${currentPage != 'Account' ? 'hidden' : ''}`}>
                        <div className='support_guide_main_title_and_icon'>
                            <img className='support_main_icon' src='icons/support/support_guide/User_scan_duotone_line@2x.png'></img>
                            <div className='support_guide_main_title'>{t('support_acount')}</div>
                        </div>
                        <br></br>
                        <hr className='support_hr'></hr>
                        <div className='support_guide_text_title'>
                            {t('acc_question_1_title')}
                        </div>
                        {t('acc_question_1')}
                        <div className='support_guide_text_title'>
                            {t('acc_question_2_title')}
                        </div>
                        {t('acc_question_2')}
                        <div className='support_guide_text_title'>
                            {t('acc_question_3_title')}
                        </div>
                        {t('acc_question_3')}
                        <div className='support_guide_text_title'>
                            {t('acc_question_4_title')}
                        </div>
                        {t('acc_question_4')}
                        <div className='support_guide_text_title'>
                            {t('acc_question_5_title')}
                        </div>
                        {t('acc_question_5')}
                        <br></br>
                        <br></br>
                    </div>

                    <div className={`support_guide_main_selected ${currentPage != 'Data' ? 'hidden' : ''}`}>
                        <div className='support_guide_main_title_and_icon'>
                            <img className='support_main_icon' src='icons/support/support_guide/Chield_check_light@2x.png'></img>
                            <div className='support_guide_main_title'>{t('support_med_data')}</div>
                        </div>
                        <br></br>
                        <hr></hr>
                        <br></br>
                        <br></br>
                        {t('med_data_info')}
                    </div>

                    <div className={`support_guide_main_selected ${currentPage != 'Help' ? 'hidden' : ''}`}>
                        <div className='support_guide_main_title_and_icon'>
                            <img className='support_main_icon' src='icons/support/support_guide/Mortarboard_alt_light@2x.png'></img>
                            <div className='support_guide_main_title'>{t('volonteers')}</div></div>
                        <br></br>
                        <hr></hr>
                        <br></br>
                        <br></br>
                        {t('volunteering_data_info')}
                        <br></br>
                        <br></br>
                    </div>

                    <div className={`support_guide_main_selected ${currentPage != 'Buy' ? 'hidden' : ''}`}>
                        <div className='support_guide_main_title_and_icon'>

                            <img className='support_main_icon' src='icons/support/support_guide/Basket_alt_3_light@2x.png'></img>
                            <div className='support_guide_main_title'>{t('Demo')}</div></div>
                        <br></br>
                        <hr></hr>
                        <br></br>
                        <br></br>
                        {t('demo_version_data_info')}
                        <br></br>
                        <br></br>
                    </div>

                </div>
            </div>
        </div >
    );
});

export default Support_guide_page;
