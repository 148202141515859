/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Radiology_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "лучевая диагностика". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Белов Михаил Александрович, Манжос Геннадий Юрьевич
*/
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';
import '../../components/Sub_Categories_Page.css';
import InProcess from "../InProcess";
import BottomCategoriesDisplay from '../../components/BottomCategoriesDisplay';

export const Radiology_EntriesPerPage = 6;

const Radiology_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Radiology_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user, user.search]);

    if (!pageData || !pageData?.subCategory)
        return;

    return <div className='custom-background_menu'>
        <div className='sub_category_title'>
            <img className='page_title_icon' src='/icons/xray_icon.png' alt='page_title_icon'></img>{t('radiology_title')}
        </div>
        <div className='sub_category_flex'>
            <BottomCategoriesDisplay categories={pageData?.subCategory} matchingCategories={pageData?.search?.matches} />
        </div>
        <div className='sub_category_bottom_flex'>
            <div className='sub_category_rightpage_info'>
                <div className='sub_category_rightpage_info_2'>
                    <InProcess />
                    {t('radiology_desc_main')}
                    <br />  <br />
                    <div className='page_title_category'>
                        {t('radiology_desc_main_title')}
                    </div>

                    <br />
                    {t('radiology_desc_main_1')}
                    <br />  <br />
                    <div className='page_title_category'>
                        {t('radiology_desc_main_title_1')}
                    </div>
                    <br />
                    {t('radiology_desc_main_2')}
                    <br />  <br />
                    <div className='page_title_category'>
                        {t('radiology_desc_title_2')}
                    </div>
                    <br />
                    {t('radiology_desc_main_3')}
                    <br />  <br />
                    <div className='page_title_category'>
                        {t('radiology_desc_title_3')}
                    </div>
                    <br />
                    {t('radiology_desc_main_4')}
                    <br />  <br />
                    <div className='page_title_category'>
                        {t('radiology_desc_title_4')}
                    </div>
                    <br />
                    {t('radiology_desc_main_5')}
                    <br /><br />
                    <div className='page_title_category'>
                        {t('radiology_desc_title_5')}
                    </div>
                    <br />
                    {t('radiology_desc_main_6')}
                </div>
            </div>
        </div>
    </div>;
});

export default Radiology_Categories_Page;