/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: DZI_Images_Display_Quiz.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. DZI_OpenSeadragon DZI_Images_Display_Quiz.js
 *	@author: Манжос Геннадий Юрьевич
*/
import DZI_ImageViewer_Quiz from './DZI_ImageViewer_Quiz';
import DZI_ProtectedImageViewer from './DZI_ProtectedImageViewer';
import "./DZI_ImageViewer.css"

function DZI_Images_Display_Quiz({ images }) {
    if (!images)
        return;

    //return <DZI_ProtectedImageViewer images={images} />;
    return <DZI_ImageViewer_Quiz images={images} />;
}

export default DZI_Images_Display_Quiz;
