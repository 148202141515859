/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: TestExtra_Data_Entrie_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. TestExtra В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Горбас Александр Петрович
*/
import React, {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom'
import {
    fetchOneTestExtra_Data,
    fetchOneTestExtra_Categories
} from "../../http/Data_API/TestExtra_Data_API";
import {
    fetchOneGender
} from "../../http/Data_API/Gender_Data_API";
import TestExtra_Data_Images_Display from "../../components/TestExtra/TestExtra_Data_Images_Display";
import {Context} from "../../index";
import {useTranslation} from "react-i18next";

const TestExtra_Data_Entrie_Page = () => {
    const {user} = useContext(Context)
    const [record, setRecord] = useState({info: []})
    const [gender, setGender] = useState({info: []})
    const [testExtra_category, setTestExtra_Category] = useState({info: []})
    const selected = useLocation()
    const { t } = useTranslation();

    useEffect(() => {
        fetchOneTestExtra_Data(selected.state.record_id).then(data => setRecord(data))
        fetchOneGender(selected.state.gender_id).then(data => setGender(data))
        fetchOneTestExtra_Categories(selected.state.categories_id).then(data => setTestExtra_Category(data))
    }, [])

    const imageurl=[]

    if (record.images_path !== undefined && record.images_path !== null)
    {
        const adres_s_bd = "" + record.images_path

        if (adres_s_bd.includes("|") === true)
        {
            const temp = adres_s_bd.split("|")
            temp.map(item => {imageurl.push([item])})
        }
        else
        {
            //console.log(adres_s_bd)
            imageurl.push([adres_s_bd])
        }
    }

    return (
        <div className="mt-2" key={record.id}
             style={{width: "auto", height: "auto", fontSize: 24}}>
            <div>{testExtra_category["name_"+user.language]}</div>
            <div>ID: {record.id}</div>
            <div>Дата рождения: {record.date_of_birth}</div>
            <div>пол: {gender["name_"+user.language]}</div>
            <div>диагноз: {record["diagnosis_name_"+user.language]}</div>
            <div>Заключение: {record["diagnosis_coclusion_"+user.language]}</div>
            <div>
                ---------------------------------------
            </div>
            {user.access_level.field_1 == 1 ? (
                <div>
                    field_1 доступен
                </div>
            ) : (
                <></>
            )}
            {user.access_level.field_2 == 1 ? (
                <div>
                    field_2 доступен
                </div>
            ) : (
                <></>
            )}
            <div>
                ---------------------------------------
            </div>
            <TestExtra_Data_Images_Display imageurl={imageurl}/>
        </div>
    );
};

export default TestExtra_Data_Entrie_Page;