/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Genetics_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Genetics_Data_API.js
 *	@author: Белов Михаил Александрович
*/
import {$authHost, $host} from '../index';
//-----------

//===========
//// begin [ Genetics ]

//===========
// begin [ Genetics_Categories ]

export const fetchGenetics_Categories = async (search, language) => {
    const {data} = await $host.get('api/genetics/genetics_categories', {params: {
            search, language
    }});
    return data;
}

export const fetchOneGenetics_Categories = async (id) => {
    const {data} = await $host.get('api/genetics/genetics_categories/' + id);
    return data;
}

// end [ Genetics_Categories ]
//===========

//-----------

//===========
// begin [ Genetics_Data ]

export const fetchGenetics_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/genetics/genetics_data', {params: {
            search, language, categories_id, page, limit
        }});
    return data;
}

export const fetchOneGenetics_Data = async (id) => {
    const {data} = await $host.get('api/genetics/genetics_data/' + id);
    return data;
}

// end [ Genetics_Data ]
//===========

//// end [ Genetics ]
////===========

//-----------