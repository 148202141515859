/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Endoscopy_Data_Store.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит так называемые хранилища, сохраняющие своё состояние объекты при переходе по страницам атласа. Data Endoscopy_Data_Store.js
 *	@author: Горбас Александр Петрович
*/
import {makeAutoObservable} from "mobx";

export default class Endoscopy_Data_Store {
    constructor(setPage) {
        this._endoscopy_categories = []
        this._selectedEndoscopy_Categories = {}

        this._endoscopy_data = []

        this.setPage = setPage;

        makeAutoObservable(this)
    }

//-----------

//===========
// begin [ Endoscopy_Categories ]

    setEndoscopy_Categories(data) {
        this._endoscopy_categories = data
    }

    get endoscopy_categories() {
        return this._endoscopy_categories
    }

    setSelectedEndoscopy_Categories(data) {
        this.setPage(1)
        this._selectedEndoscopy_Categories = data
    }

    get selectedEndoscopy_Categories() {
        return this._selectedEndoscopy_Categories
    }

// end [ Endoscopy_Categories ]
//===========

//-----------

//===========
// begin [ Endoscopy_Data ]

    setEndoscopy_Data(data) {
        this._endoscopy_data = data
    }

    get endoscopy_data() {
        return this._endoscopy_data
    }

// end [ Endoscopy_Data ]
//===========

//-----------

}
