/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Biology_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Biology_Data_API.js
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ Biology ]

//===========
// begin [ Biology_Categories ]

export const fetchBiology_Categories = async (search, language) => {
    const {data} = await $host.get('api/biology/biology_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOneBiology_Categories = async (id) => {
    const {data} = await $host.get('api/biology/biology_categories/' + id)
    return data
}

// end [ Biology_Categories ]
//===========

//-----------

//===========
// begin [ Biology_Data ]

export const fetchBiology_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/biology/biology_data', {params: {
            search, language, categories_id, page, limit
        }})
    return data
}

export const fetchOneBiology_Data = async (id) => {
    const {data} = await $host.get('api/biology/biology_data/' + id)
    return data
}

// end [ Biology_Data ]
//===========

//// end [ Biology ]
////===========

//-----------