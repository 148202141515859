/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Pimu_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Страница категорий отвечает за отображение страницы категорий.
 *	@author: Манжос Геннадий Юрьевич
*/
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../index";
import { PIMU_CATEGORIES_ROUTE } from "../utils/consts";
import { useNavigate, redirect, NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { fetchPimu_Categories } from "../http/Data_API/Pimu_Categories_Data_API";
import './Pimu_Categories_Page.css'
import { useTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import DemoVersionBanner from '../components/DemoVersionBanner';

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'instant' });
};

const CategoryCard = ({ t, user, category }) => {
    const history = useNavigate();

    const GoToCategory = (event) => {
        user.setSearch(null);
        scrollToTop(0);

        if (category.path.startsWith('http') || category.path.startsWith('https'))
            window.location.replace(category.path);
        else
            history(category.path.startsWith('/') ? category.path : PIMU_CATEGORIES_ROUTE + '/' + category.path);
    };

    const isCategoryDisabled = category.icons.includes('dark');

    const icons = {
        'ru': category.icons,
        'en': isCategoryDisabled ? category.icons.replace('dark', 'dark_eng') : category.icons
    };

    const isCategoryRestricted = user.access_level.pages_denied.includes(PIMU_CATEGORIES_ROUTE + '/' + category.path);

    return <>
        <div className={ `pimu_card-items${isCategoryRestricted ? ' dark' : ''}` } onClick={(!isCategoryDisabled && !isCategoryRestricted) ? GoToCategory : null}>
            <div className='pimu_card-icon'>
                <img src={icons[user.language]} alt='Category Icon' />
            </div>
            <div className='pimu_card-name'>
                {category['name_' + user.language]}
            </div>
        </div>
    </>;
};

const Pimu_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [ categories, setCategories ] = useState(null);

    useEffect(() => {
        if (!categories)
            fetchPimu_Categories().then( (categories) => setCategories(categories) );
    });

    if (!categories)
        return;

    return <div className='custom-background_menu'>
        <div className='text_mainmenu'>
            <div className='pimu_title'>{t('pimu_title')}</div>
        </div>
        <div className='card_list-list'>
            {categories && categories.map( (category) => <CategoryCard key={category.id} category={category} t={t} user={user} /> )}
        </div>
    </div>;
});

export default Pimu_Categories_Page;