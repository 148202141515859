/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Modelling_Data_Entrie_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "3D модели". В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Белов Михаил Александрович
 */
import { observer } from 'mobx-react-lite';
// import { Context } from '../../index';
// import { useState, useContext, useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
// import { PrepareItemPage } from '../../utils/PageDataPrepare';
// import { Modelling_EntriesPerPage } from './Modelling_Categories_Page';

// import './Modelling_Data_Entrie_Page.css';

// import * as THREE from 'three';
// import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
// //import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
// import { STLLoader } from 'three/addons/loaders/STLLoader.js';
// import TopCaseNavigation from '../../components/TopCaseNavigation';
// import BottomCaseNavigation from '../../components/BottomCaseNavigation';
// import { useLocation } from 'react-router-dom';
// import { Spinner } from 'react-bootstrap';

const rendererHeight = 720;

//  TODO: стилизация процесса загрузки модели (сейчас текст о статусе загрузки просто либо есть либо нет).
//  TODO: поддержка нескольких загрузчиков (gltf, stl) в зависимости от расширения модели.
//  FIXME: с имеющимися моделями загрузка рапортует об успехе, но модель не отображается.
const Modelling_Data_Entrie_Page = observer( () => {
    // const { t } = useTranslation();
    // const { user } = useContext(Context);
    // const location = useLocation();

    // const [ pageData, setPageData ] = useState(null);
    // const [ rendererProps, setRendererProps ] = useState(null);
    // const [ loading, setLoading ] = useState(null);

    // useEffect(() => {
    //     PrepareItemPage(location, user.language, null, Modelling_EntriesPerPage).then( (data) => {
    //         setPageData(data);

    //         setRendererProps({
    //             modelPath: data.item.path,
    //             container: null,
    //             clock: new THREE.Clock(),
    //             scene: new THREE.Scene(),
    //             loading: new THREE.LoadingManager(),
    //             progress: false,
    //             renderer: null,
    //             camera: null,
    //             model: null,
    //             material: null,
    //         });

    //         setLoading({
    //             inprogress: false,
    //             error: false,
    //         });
    //     } );
    // }, [location, user]);

    // useEffect( () => {
    //     if (!rendererProps?.modelPath || rendererProps.renderer)
    //         return;

    //     rendererProps.loading.onStart = (item, loaded, total) => {
    //         setLoading({
    //             inprogress: true,
    //             error: false,
    //         });
    //     };

    //     rendererProps.loading.onLoad = () => {
    //         setLoading({
    //             error: loading.error,
    //             inprogress: false,
    //         });
    //     };

    //     rendererProps.loading.onProgress = (item, loaded, total) => {
    //         console.log(`Loading progress: `);
    //         console.log(item, loaded, total);
    //     };

    //     rendererProps.loading.onError = (err) => {
    //         setLoading({
    //             inprogress: false,
    //             error: true,
    //         });
    //     };

    //     rendererProps.scene.background = new THREE.Color( 0xffffff );

    //     const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x8d8d8d, 3 );
    //     hemiLight.position.set( 0, 20, 0 );
    //     rendererProps.scene.add( hemiLight );

    //     const dirLight = new THREE.DirectionalLight( 0xffffff, 3 );
    //     dirLight.position.set( 3, 10, 10 );
    //     dirLight.castShadow = true;
    //     dirLight.shadow.camera.top = 2;
    //     dirLight.shadow.camera.bottom = - 2;
    //     dirLight.shadow.camera.left = - 2;
    //     dirLight.shadow.camera.right = 2;
    //     dirLight.shadow.camera.near = 0.1;
    //     dirLight.shadow.camera.far = 40;
    //     rendererProps.scene.add( dirLight );

    //     const mesh = new THREE.Mesh( new THREE.PlaneGeometry( 100, 100 ), new THREE.MeshPhongMaterial( { color: 0xf0f0f0, depthWrite: false } ) );
    //     mesh.rotation.x = - Math.PI / 2;
    //     mesh.receiveShadow = true;
    //     rendererProps.scene.add( mesh );

    //     const loader = new STLLoader( rendererProps.loading );
    //     loader.load( rendererProps.modelPath, (geometry) => {

    //         rendererProps.material = new THREE.MeshPhongMaterial( { color: 0xffff00, specular: 0x494949, shininess: 200 } );

    //         rendererProps.model = new THREE.Mesh( geometry, rendererProps.material );

    //         rendererProps.model.position.set( 0, 0, 0 );
    //         rendererProps.model.rotation.set( -Math.PI / 2, 0, 0 );
    //         rendererProps.model.scale.set( 1, 1, 1 );

    //         rendererProps.model.castShadow = true;
    //         rendererProps.model.receiveShadow = true;

    //         rendererProps.scene.add( rendererProps.model );

    //         rendererProps.renderer.setAnimationLoop( () => { rendererProps.renderer.render(rendererProps.scene, rendererProps.camera); } );
    //     } );

    //     rendererProps.container = document.querySelector('#renderer');

    //     rendererProps.renderer = new THREE.WebGLRenderer( { antialias: true } );
    //     rendererProps.renderer.setPixelRatio( window.devicePixelRatio );
    //     rendererProps.renderer.setSize( rendererProps.container.clientWidth, rendererHeight );
    //     rendererProps.renderer.shadowMap.enabled = true;
    //     rendererProps.container.appendChild( rendererProps.renderer.domElement );

    //     rendererProps.camera = new THREE.PerspectiveCamera( 45, rendererProps.container.clientWidth / rendererHeight, 1, 100 );
    //     rendererProps.camera.position.set( - 1, 2, 3 );

    //     const controls = new OrbitControls( rendererProps.camera, rendererProps.renderer.domElement );
    //     controls.enablePan = false;
    //     controls.enableZoom = false;
    //     controls.target.set( 0, 1, 0 );
    //     controls.update();

    //     window.addEventListener( 'resize', () => {
    //         if (!rendererProps.camera || !rendererProps.renderer)
    //             return;

    //         rendererProps.camera.aspect = rendererProps.container.clientWidth / rendererHeight;
    //         rendererProps.camera.updateProjectionMatrix();

    //         rendererProps.renderer.setSize(rendererProps.container.clientWidth, rendererHeight);
    //     } );
    // }, [ rendererProps ]);

    // if (!pageData || !pageData?.item)
    //     return;

    // return <>
    //     <div className="page_content_title">
    //         <img className="page_title_icon" src="/icons/3dmod_icon.png" alt="page_title_icon" />
    //         {t('modelling_title')}
    //     </div>
    //     <div className="flex_container">
    //         <div className="flex_container_title">
    //             <div className="item flex_item_1">
    //                 {pageData.category['name_' + user.language]}
    //             </div>
    //             <TopCaseNavigation className="top_case_navigation_block" pageData={pageData} />
    //         </div>

    //         <hr />

    //         <div className="case_birth">
    //             <div className='case_flex_onerow'>
    //                 <div className="case_bold_name">
    //                     {pageData.item['name_' + user.language]}
    //                 </div>
    //             </div>
    //             <div className="case_text_block">
    //                 {pageData.item['description_' + user.language]}
    //             </div>
    //         </div>

    //         <br />

    //         {loading?.inprogress && <div className='progress_bar'>
    //             <Spinner />
    //         </div> }
    //         {loading?.error && <div className='error_text'>
    //             Не удалось загрузить модель
    //         </div>}

    //         <div id='renderer'></div>

    //         <br />
    //         <BottomCaseNavigation pageData={pageData} />
    //     </div>
    // </>;
});

export default Modelling_Data_Entrie_Page;