/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Biology_Data_EntriesTable_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "паразитология". В этом файле находится содержимое страницы, которая выводится при переходе на страницу со списком кейсов, относящихся к конкретной категории. Страница отвечает за отображение данных в кейсе биологии.
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import Biology_Data_EntriesList from "../../components/Biology/Biology_Data_EntriesList";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Context } from "../../index";
import Pages from "../../components/Pages";
import './Biology_Data_EntriesTable_Page.css';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import { PreparePage } from '../../utils/PageDataPrepare';
import { Biology_EntriesPerPage } from './Biology_Categories_Page';

const Biology_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const location = useLocation();
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Biology_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    return <>
        <div id='example-destination' name='example-destination' className='biology_title'>
            <img className='page_title_icon' src='/icons/parazitology_icon.png' alt='page_title_icon'></img>{t('Bio_title')}
        </div>
        <Container>
            <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
            <div className="mt-2">
                <div md={9}>
                    <Biology_Data_EntriesList pageData={pageData} />
                    <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
                </div>
            </div>
        </Container>;
    </>
});

export default Biology_Data_EntriesTable_Page;