/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_Image.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "экг". В этом файле находится компонент, отвечающий за вывод картинки из списка картинок в кейсе.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
import React from 'react';


const ECG_Data_Image = ({ url }) => {


    return (
        <img src={url} alt={"изображение_ЭКГ"} />
    );
};

export default ECG_Data_Image;
