/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: DZI_Images_Display.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. DZI_OpenSeadragon В этом файле находится компонент, отвечающий за вывод всех картинок в кейсе.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import DZI_ImageViewer from './DZI_ImageViewer';
import DZI_ProtectedImageViewer from './DZI_ProtectedImageViewer';
import "./DZI_ImageViewer.css"

function DZI_Images_Display({images}) {
    if (!images)
        return;

    //return <DZI_ProtectedImageViewer images={images} />;
    return <DZI_ImageViewer images={images}/>;
}

export default DZI_Images_Display;
