/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: InProcess.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Страница "В разработке" отвечает за отображение страницы "В разработке".
 *	@author: Манжос Геннадий Юрьевич
*/
import React from 'react';
import { useTranslation } from "react-i18next";
import "./InProcess.css"

const InProcess = (() => {
    const { t } = useTranslation();

    return (
        <div className='in_process_block'>
            <img src='/icons/in-process.svg' alt='tool'></img>
            {t('in_process_block')}</div>
    );
});

export default InProcess;