/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Pages.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Pages.js
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import { observer } from "mobx-react-lite";
import "./Pages.css"
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//  Компонент отвечающий за отображение пагинации.
//  Необходимые для корректного отображения компоненты (props):
//  *   pageData -- объект с информацией о странице из которого будут использованы:
//      items.count -- общее кол-во элементов в отображаемом списке,
//      items.limit -- максимальное кол-во элементов отображаемых на одной странице.
//  *   onPageChanged -- callback, который будет вызван при изменении активной страницы представляющий из себя функцию с единственным аргументом: page - номер новой страницы.
//  Компонент самодостаточен в том плане, что текущую активную страницу он может получить из url search параметров с именем 'page'.
//  Компонент НЕ управляет содержимым страницы (элементами страницы), а лишь отправляет обновлённый номер активной страницы как callback и компонент, который включает эту пагинацию, должен самостоятельно обработать это изменение и обновить данные страницы.
//  Для упрощения взаимодействия (в настоящий момент) - пагинация представлена в виде компонентов 'NavLink', что по сути является ссылками и при нажатии вызывает обновление location хука, что позволяет отловить этот момент в 'useEffect' хуке и обновить данные вызывающего компонента.
//  Компонент не будет отображаться в случае, если 'pageData' или 'pageData.items' были переданы пустыми.
const Pages = observer( ({ pageData, onPageChanged }) => {
    const searchParams = new URLSearchParams(useLocation().search);
    const history = useNavigate();
    const defaultPage = searchParams.has('page') ? parseInt(searchParams.get('page')) : 1;
    const [ currentPage, setCurrentPage ] = useState(defaultPage);

    if (!pageData || !pageData?.items)
        return;

    if (!pageData.items.count)
        return;

    const pageCount = Math.ceil(pageData.items.count / pageData.items.limit);
    const pages = [];

    for (let i = 0; i < pageCount; i++) {
        pages.push(i + 1);
    }

    const ChangeCurrentPage = (event) => {
        const selectedPage = parseInt(event.target.dataset.page);
        if (!selectedPage)
            return;

        setCurrentPage(selectedPage);

        if (typeof onPageChanged !== undefined)
            onPageChanged(selectedPage);

        searchParams.set(`page`, selectedPage);
        history(`?${searchParams.toString()}`);
    };

    return <div className="pagination_block">
        <div className="container-pagination">
            {pages.map(page => <div
                key={page}
                data-page={page}
                active={currentPage === page ? 'true' : ''}
                onClick={ChangeCurrentPage}
                className={currentPage === page ? "pagination_active" : "pagination_noactive"}>
                    {page}
            </div>)}
        </div>
    </div>;
});

export default Pages;
