/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Patanatomy_Data_EntriesList.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "патанатомия". В этом файле находится компонент, отвечающий за вывод списка кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
import { observer } from "mobx-react-lite";
import Patanatomy_Data_EntriesItem from "./Patanatomy_Data_EntriesItem";
import "./Dzi_Data_EntriesList.css"

const Patanatomy_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData || !pageData?.items)
        return;

    return <div className='dzi_items_flex'>
        {pageData.items.rows.map((item) => <Patanatomy_Data_EntriesItem key={item.id} item={item} />)}
    </div>;
});

export default Patanatomy_Data_EntriesList;