/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Modelling_Data_EntriesItem.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "3D модели". В этом файле находится компонент, отвечающий за вывод одного элемента в списке кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич,  Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Modelling_CATEGORIES_ROUTE, Modelling_DATA_ENTRIE, DEMO_VERSION_ROUTE } from "../../utils/consts";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";

const Modelling_Data_EntriesItem = ({ item }) => {
    const { user } = useContext(Context);
    const history = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    if (!item)
        return;

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const TryNavigateToCase = () => {
        if (user.access_level.demo_access && !item.demo_access) {
            scrollToTop();
            history(DEMO_VERSION_ROUTE);
            return;
        }

        scrollToTop();
        history(`${Modelling_CATEGORIES_ROUTE}${Modelling_DATA_ENTRIE}/${item.id}?${searchParams.toString()}`);
    };

    if (!item.preview)
        item.preview = '/icons/no_photo_preview.png';

    return <div className='case_preview-item1' onClick={TryNavigateToCase} >
        {item.demo_access && user.access_level.demo_access && <div className='demo_version_tif_preview'>
            {t("Demo")}
        </div>}
        <img src={item.preview} alt="image preview" onClick={TryNavigateToCase} />
        <div className="case_preview_info1">
            <div className="case_preview_title1">
                {item['name_' + user.language]}
            </div>
        </div>
    </div>;
};

export default Modelling_Data_EntriesItem;