/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Ophthalmology_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Относится к категори "Офтальмология".
 *	@author: Белов Михаил Александрович
*/
import { $host } from '../index';

//-----------

//===========
//// begin [ Ophthalmology ]

//===========
// begin [ Ophthalmology_Categories ]

export const fetchOphthalmology_Categories = async (search, language) => {
    const {data} = await $host.get('api/ophthalmology/ophthalmology_categories', { params: { search, language } });
    return data;
}

export const fetchOneOphthalmology_Categories = async (id) => {
    const { data } = await $host.get('api/ophthalmology/ophthalmology_categories/' + id);
    return data;
}

// end [ Ophthalmology_Categories ]
//===========

//-----------

//===========
// begin [ Ophthalmology_Data ]

export const fetchOphthalmology_Data = async (search, language, categories_id, page, limit) => {
    const { data } = await $host.get('api/ophthalmology/ophthalmology_data', { params: { search, language, categories_id, page, limit } });
    return data;
}

export const fetchOneOphthalmology_Data = async (id) => {
    const { data } = await $host.get('api/ophthalmology/ophthalmology_data/' + id);
    return data;
}

// end [ Ophthalmology_Data ]
//===========

//// end [ Ophthalmology ]
////===========

//-----------