/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Botany_Data_Entrie_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "ботаника". В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";

import { PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { Botany_EntriesPerPage } from './Botany_Categories_Page';
import ImageProtected from '../../components/ProtectedImage';
import DZI_Images_Display from '../../components/DZI_OpenSeadragon/DZI_Images_Display';

const Botany_Data_Entrie_Page = observer(() => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});
    const location = useLocation();

    useEffect(() => {
        PrepareItemPage(location, user.language, null, Botany_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData?.category || !pageData?.item)
        return;

    return <div>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/botanica_icon.png' alt='page_title_icon'></img>{t("botany_title")}
        </div>
        <div className="flex_container" key={pageData.id}>
            <div className='flex_container_title'>
                <div className='item flex_item_1'>
                    {pageData.category['name_' + user.language]}
                </div>
                <div className='top_case_navigation_block'>
                    <TopCaseNavigation pageData={pageData} />
                </div>
            </div>

            <hr />

            <div className='case_birth'>
                <div className='case_text_info'></div>
                <div className='case_bold_name'>{pageData.item['name_' + user.language]}</div>
                <div className='case_text_block'>{pageData.item['description_' + user.language]}</div>
            </div>

            <div className='dzi_section'>
                <DZI_Images_Display images={pageData.item.images_path_dzi} />
            </div>

            <BottomCaseNavigation pageData={pageData} />
        </div>
    </div>;
});

export default Botany_Data_Entrie_Page;
