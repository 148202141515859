/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: User_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. User_API.js
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import {$authHost, $host} from "./index";
import jwt_decode from "jwt-decode";

export const registration = async ({ email, password, role, specialty, position, university, code }) => {
    try
    {
        const requestData = {
            email: email,
            password: password,
            role: role,
            specialty: specialty,
            position: position,
            university: university,
            code: code
        };

        const { data } = await $authHost.post('api/user/registration', requestData);

        if (data?.error)
            throw new Error(data.error);

        if (data?.token !== null)
        {
            localStorage.setItem('token', data.token);
            const decoded = jwt_decode(data.token);

            return {
                tokenData: decoded,
                user: data.user,
            };
        }
        else
        {
            throw new Error('сервер вернул пустой токен');
        }
    }catch (error)
    {
        console.error('register Ошибка при запросе на сервер: ', error.message);

        return {
            error: error?.response?.data?.message ?? error.message,
        };
    }
}

export const login = async (email, password) => {
    try
    {
        const requestData = {
            email: email,
            password: password,
        };

        const { data } = await $authHost.post('api/user/login', requestData);

        if (data?.error)
            throw new Error(data.error);

        if (data?.token !== null)
        {
            localStorage.setItem('token', data.token);
            const decoded = jwt_decode(data.token);

            return {
                tokenData: decoded,
                user: data.user,
            };
        }
        else
        {
            throw new Error('сервер вернул пустой токен');
        }
    }catch (error)
    {
        console.error('login Ошибка при запросе на сервер: ', error.message);

        return {
            error: error?.response?.data?.message ?? error.message,
        };
    }
}

export const check = async () => {
    try {
        //  Если токен уже есть, но он повреждён - удалить его, прежде чем отправлять запрос на проверку данных.
        if (localStorage.getItem('token'))
        {
            try
            {
                jwt_decode(localStorage.getItem('token'));
            }catch (error)
            {
                localStorage.removeItem('token');
            }
        }

        //  Отправить запрос на проверку авторизации.
        const { data } = await $authHost.get('api/user/auth');

        if (data?.error)
            throw new Error(data.error);

        if (data?.token !== null)
        {
            localStorage.setItem('token', data.token);
            const decoded = jwt_decode(data.token);

            return {
                tokenData: decoded,
                user: data.user,
            };
        }
        else
        {
            console.error("check Сервер вернул пустой токен");

            return null;
        }
    } catch (error) {
        console.error("check Ошибка при запросе на сервер:", error.message);

        localStorage.removeItem('token');

        return null;
    }
}

//  Отправка запроса на проверку действительности токена восстановления.
//  Единственным аргументом в функции передаётся сам токен.
export const restoreCheckToken = async (token) => {
    try
    {
        const requestData = { token: token };
        const { data } = await $authHost.post('api/user/restorecheck', requestData);

        if (!data?.result || data?.error)
            throw new Error(data?.error ?? `без описания`);

        return true;
    }catch (error)
    {
        console.error(`restoreCheckToken Ошибка при запросе на сервер: ${error.message}!`);
        return false;
    }
};

//  Отправка запроса на восстановление пароля.
//  В качестве аргументов возможно два варианта:
//  *   token и password -- в случае, когда вызов происходит со страницы обновления пароля, на которую можно попасть только зная токен;
//  *   email -- в случае, когда вызов происходит со страницы восстановления пароля, на которую можно попасть если не указать токен.
export const restore = async ({ token, email, password }) => {
    try
    {
        const requestData = {};
        if (token && password && !email)
        {
            requestData.token = token;
            requestData.password = password;
        }

        if (email && !token && !password)
        {
            requestData.email = email;
        }

        if (token && password && email)
            throw new Error(`В функцию отправки запроса на восстановление были переданы неверные аргументы`);

        const { data } = await $authHost.post('api/user/restore', requestData);

        if (!data?.result || data?.error)
            throw new Error(data?.error ?? `без описания`);

        return true;
    }catch (error)
    {
        console.error(`restore Ошибка при запросе на сервер: ${error.message}!`);
        return false;
    }
};

//  Отправка запроса на обновление пароля пользователя.
//  В качестве аргументов ожидается:
//  *   passwordCurrent -- текущий пароль,
//  *   passwordNew -- новый пароль.
export const passwordUpdate = async ({ passwordCurrent, passwordNew }) => {
    try
    {
        if (!passwordCurrent || !passwordNew)
            throw new Error('profile_edit_error_no_password');

        if (passwordCurrent == passwordNew)
            throw new Error('profile_edit_error_same_password');

        const { data } = await $authHost.post('api/user/passwordupdate', { passwordCurrent, passwordNew });

        if (!data?.result || data?.error)
            throw new Error(data?.error ?? 'без описания');

        return {
            result: true,
            message: data.message,
        };
    }catch (error)
    {
        console.error(`passwordUpdate Ошибка при запросе на сервер: ${error.message}!`);
        return {
            result: false,
            message: error?.response?.data?.message ?? error.message,
        };
    }
}

//  Отправка запроса на обновление информации профиля пользователя.
//  В качестве аргументов принимается объект 'profile' ключи которого - это поля профиля.
//  В результате запроса будут обновлены лишь те поля, которые были в 'profile'.
export const profileUpdate = async ({ profile }) => {
    try
    {
        if (!profile)
            throw new Error(`Не указаны обновлённые параметры профиля`);

        const { data } = await $authHost.post('api/user/profileupdate', { profile });

        if (!data?.result || data?.error)
            throw new Error(data?.error ?? 'без описания');

        return {
            result: true,
            message: data.message,
        };
    }catch (error)
    {
        console.error(`profileUpdate Ошибка при запросе на сервер: ${error.message}!`);
        return {
            result: false,
            message: error?.response?.data?.message ?? error.message,
        };
    }
};

//  Авторизация через Moodle.
export const moodleAuth = async (code, clientid) => {
    try
    {
        if (!code)
            throw new Error(`Не указан код авторизации`);

        if (!clientid)
            throw new Error(`Не указан идентификатор клиента`);

        const { data } = await $authHost.post('api/user/moodleauth', { code, clientid });

        if (!data?.result || data?.message)
            throw new Error(data?.message ?? 'без описания');

        if (!data?.token)
            throw new Error(data?.message ?? 'нет данных авторизации');

        localStorage.setItem('token', data.token);
        const decoded = jwt_decode(data.token);

        return {
            result: true,
            tokenData: decoded,
            user: data?.user,
        };
    }catch (error)
    {
        const errorMessage = error?.response?.data?.message ?? error.message;

        console.error(`moodleAuth Ошибка при авторизации через moodle: ${errorMessage}!`);
        return {
            result: false,
            message: errorMessage
        }
    }
};

//  Запрос полного списка ролей.
//  Аргумент 'filters' допускает:
//  *   can_select -- будут выбраны лишь те роли, что доступны при регистрации;
//  *   can_change -- будут выбраны лишь те роли, что пользователь может изменить самостоятельно.
export const getRoles = async (filters) => {
    try
    {
        const { data } = await $host.post('api/user/roles', { filters });

        if (!data?.result || data?.message)
            throw new Error(data?.message ?? 'без описания');

        if (!data?.roles)
            throw new Error(data?.message ?? 'список не получен');

        return {
            result: true,
            roles: data.roles,
        };
    }catch (error)
    {
        const errorMessage = error?.reponse?.data?.message ?? error.message;

        console.error(`getRoles Ошибка при получении списка ролей: ${errorMessage}`);
        return {
            result: false,
            message: errorMessage,
            roles: [],
        };
    }
};

//  Запрос списка пользователей, которые контроллируются пользователем 'userId'.
//  Для предотвращения несанкционированного доступа, сервер ожидает также токен авторизации пользователя, для проверки соответствия
//  запрашиваемого пользователя с тем, что "зашито" в токене.
export const getMyUsers = async (userId) => {
    try
    {
        const token = localStorage.getItem('token');

        const { data } = await $host.post('api/user/myusers', { userId, token });

        if (!data?.result || data?.message)
            throw new Error(data?.message ?? 'без описания');

        if (!data?.users)
            throw new Error(data?.message ?? 'список не получен');

        return {
            result: true,
            users: data.users,
        };
    }catch (error)
    {
        const errorMessage = error?.reponse?.data?.message ?? error.message;

        console.error(`getMyUsers Ошибка при получении списка пользователей: ${errorMessage}`);
        return {
            result: false,
            message: errorMessage,
            users: [],
        };
    }
};

//  Запрос на создание нового пользователя, который будет контроллироваться другим пользователем.
export const registerControlledUser = async (userData) => {
    try
    {
        const token = localStorage.getItem('token');

        const { data } = await $host.post('api/user/registrationcontrolled', { token, userData });

        if (!data?.result || data?.message)
            throw new Error(data?.message ?? 'без описания');

        return {
            result: true,
            user: data.user,
        };
    }catch (error)
    {
        const errorMessage = error?.response?.data?.message ?? error.message;

        console.error(`registerControlledUser Ошибка при создании нового пользователя: ${errorMessage}`);
        return {
            result: false,
            message: errorMessage,
            user: null,
        };
    }
};

//  Запрос на удаление пользователя, контроль за которым осуществляет другой пользователь.
export const deleteControlledUser = async (userData) => {
    try
    {
        const token = localStorage.getItem('token');

        const { data } = await $host.post('api/user/deletecontrolled', { token, userData });

        if (!data?.result || data?.message)
            throw new Error(data?.message ?? 'без описания');

        return {
            result: true,
        };
    }catch (error)
    {
        const errorMessage = error?.response?.data?.message ?? error.message;

        console.error(`deleteControlledUser Ошибка при удалении подконтрольного пользователя: ${errorMessage}`);
        return {
            result: false,
            message: errorMessage,
        };
    }
};

//  Запрос на обновление информации о пользователе, контроль над которым, осуществляет другой пользователь.
export const updateControlledUser = async (userData) => {
    try
    {
        const token = localStorage.getItem('token');

        const { data } = await $host.post('api/user/updatecontrolled', { token, userData });

        if (!data?.result || data?.message)
            throw new Error(data?.message ?? 'без описания');

        return {
            result: true,
            user: data.user,
        };
    }catch (error)
    {
        const errorMessage = error?.response?.data?.message ?? error.message;

        console.error(`updateControlledUser Ошибка при изменении подконтрольного пользователя: ${errorMessage}`);
        return {
            result: false,
            message: errorMessage,
        };
    }
};

//  Сброс пароля для пользователя, контроль за которым осуществляет другой пользователь.
export const resetPasswordControlledUser = async (userData) => {
    try
    {
        const token = localStorage.getItem('token');

        const { data } = await $host.post('api/user/resetpasswordcontrolled', { token, userData });

        if (!data?.result || data?.message)
            throw new Error(data?.message ?? 'без описания');

        return {
            result: true,
        };
    }catch (error)
    {
        const errorMessage = error?.response?.data?.message ?? error.message;

        console.error(`resetPasswordControlledUser Ошибка при удалении пароля пользователя: ${errorMessage}`);
        return {
            result: false,
            message: errorMessage,
        };
    }
};

//  Создание большого количества пользователей с указанной ролью.
export const registerControlledUserEnMasse = async (requestData) => {
    try
    {
        const token = localStorage.getItem('token');

        const { data } = await $host.post('api/user/registercontrolledmass', { token, requestData });

        if (!data?.result || data?.message)
            throw new Error(data?.message ?? 'без описания');

        return {
            result: true,
        };
    }catch (error)
    {
        const errorMessage = error?.response?.data?.message ?? error.message;

        console.error(`registerControlledUserEnMasse Ошибка при создании большого количества пользователей: ${errorMessage}`);
        return {
            result: false,
            message: errorMessage,
        };
    }
};

//  Отправка запроса на формирование списка пользователей, которые были созданы этим пользователем.
//  Текущий пользователь берётся из токена авторизации.
export const prepareUsersExportList = async () => {
    try
    {
        const token = localStorage.getItem('token');

        const response = await $host.get('api/user/usersexportlist/' + token, { responseType: 'blob' });

        if (response.status != 200)
            throw new Error(response);

        return response.data;
    }catch (error)
    {
        const errorMessage = error?.response?.data?.message ?? error.message;

        console.error(`prepareUsersExportList Ошибка при формировании выгрузки пользователей: ${errorMessage}`);
        return {
            result: false,
            message: errorMessage,
        };
    }
};

//  Отправка запроса на разлогинивание пользователя. Это очистит поле "последний IP" в БД записи пользователя, чтобы была возможность входа снова.
export const processUserLogOut = async () => {
    try
    {
        const token = localStorage.getItem('token');

        const response = await $host.post('api/user/logout', { token });
        if (response.status != 200)
            throw new Error(response);

        return response.data;
    }catch (error)
    {
        const errorMessage = error?.response?.data?.message ?? error.message;

        console.error(`processUserLogOut Ошибка при формировании выгрузки пользователей: ${errorMessage}`);
        return {
            result: false,
            message: errorMessage,
        };
    }
};