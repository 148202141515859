/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Biology_Data_Store.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит так называемые хранилища, сохраняющие своё состояние объекты при переходе по страницам атласа. Data Biology_Data_Store.js
 *	@author: Горбас Александр Петрович
*/
import {makeAutoObservable} from "mobx";

export default class Biology_Data_Store {
    constructor(setPage) {

        this._biology_categories = []
        this._selectedBiology_Categories = {}

        this._biology_data = []

        this.setPage = setPage;

        makeAutoObservable(this)
    }

//-----------

//===========
// begin [ Biology_Categories ]

    setBiology_Categories(data) {
        this._biology_categories = data
    }

    get biology_categories() {
        return this._biology_categories
    }

    setSelectedBiology_Categories(data) {
        this.setPage(1)
        this._selectedBiology_Categories = data
    }

    get selectedBiology_Categories() {
        return this._selectedBiology_Categories
    }

// end [ Biology_Categories ]
//===========

//-----------

//===========
// begin [ Biology_Data ]

    setBiology_Data(data) {
        this._biology_data = data
    }

    get biology_data() {
        return this._biology_data
    }

// end [ Biology_Data ]
//===========

//-----------

}
