/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. ECG_Data_API.js
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ ECG ]

//===========
// begin [ ECG_Categories ]

export const fetchECG_Categories = async (search, language) => {
    const {data} = await $host.get('api/ecg/ecg_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOneECG_Categories = async (id) => {
    const {data} = await $host.get('api/ecg/ecg_categories/' + id)
    return data
}

// end [ ECG_Categories ]
//===========

//-----------

//===========
// begin [ ECG_Data ]

export const fetchECG_Data = async (search, language, gender_id, categories_id, ages, page, limit) => {
    const {data} = await $host.get('api/ecg/ecg_data', {params: {
            search, language, gender_id, categories_id, ages, page, limit
        }})
    return data
}

export const fetchOneECG_Data = async (id) => {
    const {data} = await $host.get('api/ecg/ecg_data/' + id)
    return data
}

// end [ ECG_Data ]
//===========

//-----------

//===========
// begin [ ECG_Data_Con_Parameters ]

export const fetchECG_Data_Con_Parameters = async () => {
    const {data} = await $host.get('api/ecg/ecg_data_con_parameters', )
    return data
}

export const fetchOneECG_Data_Con_Parameters = async (id) => {
    const {data} = await $host.get('api/ecg/ecg_data_con_parameters/' + id)
    return data
}

export const findECG_Data_Con_Parameters = async (ecg_data_id) => {
    const {data} = await $host.get('api/ecg/ecg_data_con_parameters/find/' + ecg_data_id)
    return data
}

// end [ ECG_Data_Con_Parameters ]
//===========

//-----------

//===========
// begin [ ECG_Data_Options ]

export const fetchECG_Data_Options = async () => {
    const {data} = await $host.get('api/ecg/ecg_data_options', )
    return data
}

export const fetchOneECG_Data_Options = async (id) => {
    const {data} = await $host.get('api/ecg/ecg_data_options/' + id)
    return data
}

export const findECG_Data_Options = async (ecg_data_id) => {
    const {data} = await $host.get('api/ecg/ecg_data_options/find/' + ecg_data_id)
    return data
}

// end [ ECG_Data_Options ]
//===========

//-----------

//===========
// begin [ ECG_Data_OptionsDefault ]

export const fetchECG_Data_OptionsDefault = async () => {
    const {data} = await $host.get('api/ecg/ecg_data_optionsDefault', )
    return data
}

export const fetchOneECG_Data_OptionsDefault = async (id) => {
    const {data} = await $host.get('api/ecg/ecg_data_optionsDefault/' + id)
    return data
}

export const findECG_Data_OptionsDefault = async (gender_id, categories_id) => {
    const {data} = await $host.get('api/ecg/ecg_data_optionsDefault/find/',
        {params: {gender_id, categories_id}}
    )
    return data
}

// end [ ECG_Data_OptionsDefault ]
//===========

//// end [ ECG ]
////===========

//-----------