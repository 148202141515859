/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Endoscopy_Data_EntriesItem.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "эндоскопия". В этом файле находится компонент, отвечающий за вывод одного элемента в списке кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
    DEMO_VERSION_ROUTE,
    Endoscopy_CATEGORIES_ROUTE,
    Endoscopy_DATA_ENTRIE
} from "../../utils/consts";
import "./Endoscopy_Data_EntriesList.css"
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { useTranslation } from "react-i18next";
import { Context } from "../../index";

const Endoscopy_Data_EntriesItem = ({ record, gender }) => {
    const { user } = useContext(Context)
    const history = useNavigate()
    const { t } = useTranslation();

    useEffect(() => {

        // Registering the 'begin' event and logging it to the console when triggered.
        Events.scrollEvent.register('begin', (to, element) => {
        });

        // Updating scrollSpy when the component mounts.
        scrollSpy.update();

        // Returning a cleanup function to remove the registered events when the component unmounts.
        return () => {
            Events.scrollEvent.remove('begin');
        };
    }, []);

    document.addEventListener('DOMContentLoaded', function () {
        var initialScrollPos = 0;

        window.addEventListener('popstate', function () {
            // Восстанавливаем первоначальную позицию прокрутки при возврате назад
            window.scrollTo({ top: initialScrollPos, behavior: 'instant' });
        });
    });

    // прокрутка вверх при 'Вперед'
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const handleClick = () => {
        if (user.access_level.demo_access == false) {
            full_version();
        }
        else if (record.demo_access == user.access_level.demo_access) {
            full_version();
        }
        else {
            demo_version();
        }
    };

    const full_version = () => {
        history(
            Endoscopy_CATEGORIES_ROUTE + Endoscopy_DATA_ENTRIE + '/' + record.id,
            {
                state: {
                    record_id: record.id,
                    categories_id: record.categories_id
                }
            }
        );
    }

    const demo_version = () => {
        history(
            DEMO_VERSION_ROUTE
        );
    }

    //console.log("Endoscopy_Data_EntriesItem")
    //console.log(record)

    return (
        <div className='flex_container_gastroscopy' onClick={() => {
            handleClick();
            scrollToTop(200);
        }}>
            <div className='case_id_circle'>
                <div className='case_id_number'>{record.id}</div>
            </div>
            {/* <div className='date_birth'>
                {record["patient_info_" + user.language]}
            </div> */}
            <div className='record_description'>
                {record["description_" + user.language]}
            </div>
            {((record.demo_access === true) && (user.access_level.demo_access === true)) ? (
                <div className='demo_version_endoscopy'>
                    {t("Demo")}
                </div>
            ) : (
                <></>
            )}
        </div >
    );
};

export default Endoscopy_Data_EntriesItem;