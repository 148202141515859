/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Gistology_Data_Entrie_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "гистология". В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DZI_Images_Display from '../../components/DZI_OpenSeadragon/DZI_Images_Display';
import { useTranslation } from 'react-i18next';
import { Context } from '../../index';
import { PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { Gistology_EntriesPerPage } from './Gistology_Categories_Page';

const Gistology_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const location = useLocation();
    const [pageData, setPageData] = useState({});

    useEffect(() => {
        PrepareItemPage(location, user.language, user.gender, Gistology_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData?.item) return null;

    const description2 = pageData.item['description2_' + user.language];

    return (
        <>
            <div className='page_content_title'>
                <img className='page_title_icon' src='/icons/histology_icon.png' alt='page_title_icon' />
                {t("Histology_title")}
            </div>
            <div className="flex_container">
                <div className='flex_container_title'>
                    <div className='flex_item_1'>
                        {pageData.category['name_' + user.language]}
                    </div>
                    <TopCaseNavigation className='top_case_navigation_block' pageData={pageData} />
                </div>
                <hr />
                <div className='case_birth'>
                    <div className='case_text_info'></div>
                    <div className='case_bold_name'>
                        {pageData.item['name_' + user.language]}
                    </div>
                    <div className='case_text_block'>
                        {pageData.item['description_' + user.language]}
                    </div>
                    {description2 && (
                        <>
                            <div className='case_text_info'>{t('description')}</div>
                            <div className='case_text_block'>
                                {description2}
                            </div>
                        </>
                    )}
                </div>
                <div className='dzi_section'>
                    <DZI_Images_Display images={pageData.item.images_path_dzi} />
                </div>
                <BottomCaseNavigation pageData={pageData} />
            </div>
        </>
    );
};

export default Gistology_Data_Entrie_Page;
