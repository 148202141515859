/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: TestExtra_Data_EntriesItem.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. TestExtra В этом файле находится компонент, отвечающий за вывод одного элемента в списке кейсов конкретной категории.
 *	@author: Горбас Александр Петрович
*/
import React, {useContext} from 'react';
import {Card, Col} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {DEMO_VERSION_ROUTE, TestExtra_CATEGORIES_ROUTE, TestExtra_DATA_ENTRIE} from "../../utils/consts";
import {Context} from "../../index";
import {useTranslation} from "react-i18next";

const TestExtra_Data_EntriesItem = ({record, gender}) => {
    const history = useNavigate()
    const {user} = useContext(Context)
    const { t } = useTranslation();

    const handleClick = () => {
        if (user.access_level.demo_access == false) {
            full_version();
        }
        else if (record.demo_access == user.access_level.demo_access) {
            full_version();
        }
        else {
            demo_version();
        }
    };

    const full_version = () => {
        history(TestExtra_CATEGORIES_ROUTE + TestExtra_DATA_ENTRIE + '/' + record.id, {
            state: {
                record_id: record.id,
                gender_id: record.gender,
                categories_id: record.categories_id
            }
        });
    }

    const demo_version = () => {
        history(
            DEMO_VERSION_ROUTE
        );
    }

    //console.log(record)
    //console.log(gender)

    return (
        <Col md={3} className={"mt-3"} onClick={() =>  {
            handleClick();
        }}>
            <Card style={{width: 200, cursor: 'pointer'}} border={"dark"}>
                <div className="text-black-100 m-3 mt-2">
                    <div>ID: {record.id}</div>
                    <div>дата рождения: {record.date_of_birth}</div>
                    <div>пол: {gender["name_"+user.language]}</div>
                    { ((record.demo_access === true) && (user.access_level.demo_access === true)) ? (
                        <div>
                            {t("Demo")}
                        </div>
                    ) : (
                        <></>
                    )}
                    {user.access_level.field_1 == 1 ? (
                        <div>
                            field_1 доступен
                        </div>
                    ) : (
                        <></>
                    )}
                    {user.access_level.field_2 == 1 ? (
                        <div>
                            field_2 доступен
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </Card>
        </Col>
    );
};

export default TestExtra_Data_EntriesItem;