/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Modelling_Data_EntriesList.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "3D модели". В этом файле находится компонент, отвечающий за вывод списка кейсов конкретной категории.
 *	@author: Белов Михаил Александрович
*/
import { observer } from "mobx-react-lite";
import Modelling_Data_EntriesItem from "./Modelling_Data_EntriesItem";
import { useState, useEffect } from "react";

const Modelling_Data_EntriesList = observer(({ pageData }) => {
    if (!pageData?.items?.rows)
        return;

    return <div className="ecg_list_flex">
        {pageData.items.rows.map((item) => <Modelling_Data_EntriesItem key={item.id} item={item} />)}
    </div>;
});

export default Modelling_Data_EntriesList;