/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Ophthalmology_Data_Entrie_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "офтальмология". В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { Context } from "../../index";

import { PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { Ophthalmology_EntriesPerPage } from './Ophthalmology_Categories_Page';
import SliderProtected from '../../components/ProtectedImageSlider';

const Ophthalmology_Data_Entrie_Page = () => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});
    const location = useLocation();

    useEffect(() => {
        PrepareItemPage(location, user.language, null, Ophthalmology_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData?.category || !pageData?.item)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/stethoscope_icon.png' alt='page_title_icon'></img>{t("Ophthalmoscopy_title")}
        </div>
        <div className="flex_container">
                <div className="flex_container_title">
                    <div className="item flex_item_1">
                        {pageData.category['name_' + user.language]}
                    </div>
                    <TopCaseNavigation className="top_case_navigation_block" pageData={pageData} />
                </div>
                <hr />
                <div className="case_birth">
                    <div className="case_text_info"></div>
                    <div className='case_flex_onerow'>
                        <div className="case_bold_name">
                            {pageData.item['name_' + user.language]}
                        </div>
                    </div>
                        <div className="case_text_info">{t('conclusion')}</div>
                        <div className="case_text_block">
                            {pageData.item['conclusion_' + user.language]}
                        </div>
                </div>
                <div className="dzi_section">
                    <SliderProtected
                        imageurl={pageData.item.images_path}
                        imagesPerView={1}
                    />
                </div>
                <div className='case_text_info'>{t('description')}</div>
                <div className="case_text_block mb-4">
                    {pageData.item['description_' + user.language]}
                </div>
                <BottomCaseNavigation pageData={pageData} />
            </div>
    </>;
};

export default Ophthalmology_Data_Entrie_Page;
