/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: System_Requirements.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Страница требований отвечает за отображение страницы требований.
 *	@author: Манжос Геннадий Юрьевич
*/
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import './Terms_Page.css';


const System_Requirements = observer(() => {
    const history = useNavigate();
    const { t } = useTranslation();


    function openMenu(evt, selectedName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("terms_main_block_selected");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByClassName("terms_sidebar_menu");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        document.getElementById(selectedName).style.display = "block";
        evt.currentTarget.className += " active";
    };

    useEffect(() => {
        const fakeEvent = { currentTarget: { className: "" } };
        openMenu(fakeEvent, 'Требования по установке и эксплуатации программного обеспечения');
    }, []); // Пустой массив зависимостей означает, что эффект будет выполнен только при первом рендере


    return <>
        <div className='terms_page'>
            <div className='terms_grid'>
                <div className='terms_sidebar'>
                    <img src={t('logo_pic_about')} className='terms_sidebar_title_img'></img>
                    <div className='terms_sidebar_block'>
                        <div className='terms_sidebar_content'>
                            <img src='/icons/terms/Component 15.png'></img>{t('Maintenance')}</div>

                        <div className='terms_sidebar_menu' onClick={(e) => openMenu(e, 'Требования по установке и эксплуатации программного обеспечения')}>{t('System requirements')}</div>

                    </div>
                </div>
                <div className='terms_main'>
                    <div className='terms_main_title'>{t('sys_req_name')}</div>
                    <div className='terms_main_block'>
                        <div className='terms_main_block_selected' id="Требования по установке и эксплуатации программного обеспечения">
                            <button className='user_guide_block_button'
                                onClick={() => window.open(t('Sys_req_pdf'), "_blank")}
                            >
                                <img src='/icons/button_pdf_file.svg' />
                                <br></br>
                                <br></br>
                                {t("pdf_open")}
                            </button>
                            <div className='terms_main_desc'>
                                {t('3.1')}
                                <br></br>
                                <br></br>
                                <ul>
                                    <li>{t('PC with Windows 10 or higher')}</li>
                                    <li>{t('PC with Linux OS (Ubuntu 18.4 and higher)')}</li>
                                    <li>{t('The latest versions of Google Chrome')}</li>
                                    <li>{t('Connection to a corporate network via Wi-Fi')}</li>
                                </ul>
                                {t('3.2')}
                                <br></br>
                                <br></br>
                                <ul>
                                    <li>{t('PC with Linux OS (Ubuntu 18.4 and higher), Windows 11 or higher')}</li>
                                    <li>{t('The latest versions of Google Chrome')}</li>
                                    <li>{t('Connection to a corporate network via Wi-Fi')}</li>
                                </ul>
                                {t('3.3')}
                                <br></br>
                                <br></br>
                                {t('No installations')}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    </>;
});

export default System_Requirements; 
