/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: TestExtra_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. TestExtra_Data_API.js
 *	@author: Горбас Александр Петрович
*/
import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ TestExtra ]

//===========
// begin [ TestExtra_Categories ]

export const fetchTestExtra_Categories = async () => {
    const {data} = await $host.get('api/testExtra/testExtra_categories', )
    return data
}

export const fetchOneTestExtra_Categories = async (id) => {
    const {data} = await $host.get('api/testExtra/testExtra_categories/' + id)
    return data
}

// end [ TestExtra_Categories ]
//===========

//-----------

//===========
// begin [ TestExtra_Data ]

export const fetchTestExtra_Data = async (gender_id, categories_id, page, limit= 5) => {
    const {data} = await $host.get('api/testExtra/testExtra_data', {params: {
            gender_id, categories_id, page, limit
        }})
    return data
}

export const fetchOneTestExtra_Data = async (id) => {
    const {data} = await $host.get('api/testExtra/testExtra_data/' + id)
    return data
}

// end [ TestExtra_Data ]
//===========

//// end [ TestExtra ]
////===========

//-----------