/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Ultrasound_Data_Entrie_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "ультразвуковая диагностика". В этом файле находится содержимое страницы, которая выводится при переходе на страницу кейса конкретной категории.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Context } from "../../index";
import { observer } from 'mobx-react-lite';

import { PrepareItemPage } from '../../utils/PageDataPrepare';
import TopCaseNavigation from '../../components/TopCaseNavigation';
import BottomCaseNavigation from '../../components/BottomCaseNavigation';
import { Ultrasound_EntriesPerPage } from './Ultrasound_Categories_Page';
import SliderProtected from '../../components/ProtectedImageSlider';

const Ultrasound_Data_Entrie_Page = observer(() => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const selectedGender = new URLSearchParams(location.search).get('gender');
    const [ descriptionIndex, setDescriptionIndex ] = useState(0);

    useEffect(() => {
        PrepareItemPage(location, user.language, selectedGender, Ultrasound_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData?.category || !pageData?.item)
        return;

    return <div className='ecg_page_block'>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/ultrasound_icon.png' alt='page_title_icon'></img>{t('ultrasound_title')}
        </div>
        <div className="flex_container" key={pageData.item.id}>
            <div className='flex_container_title'>
                <div className='item flex_item_1'>
                    {pageData.item['name_' + user.language]}
                </div>
                <TopCaseNavigation className='top_case_navigation_block' pageData={pageData} />
            </div>

            <hr />
            <br></br>

            <div className='birth_and_gender_grid'>
                <div className='case_gender'>
                    <div className='case_gender_block'>
                        <div className="case_text_info">{t('gender')}</div>
                        {pageData.item.gender['name_' + user.language]}</div>
                </div>

                <div className='case_birth'>
                    <div className='case_birth_date'>
                        <div className='case_text_info'>{t('age')}</div>
                        {pageData.item.age}</div>
                </div>

                <div className='case_results' style={{ display: pageData.item['diagnosis_name_' + user.language] ? 'block' : 'none' }}>
                    <div className='case_text_block'>
                        <div className='case_text_info'>{t('diagnosis')}</div>
                        {pageData.item['diagnosis_name_' + user.language]}</div>
                </div>
            </div>

            <div className='case_flex'>
                <div className='case_conclusion'>
                    <div className='case_text_info'>{t('conclusion')}</div>
                    <div className='case_text_block_bold'>
                        {pageData.item['conclusion_' + user.language]}</div>
                </div>
            </div>

            <div className="dzi_section">
                <SliderProtected
                    imagesPerView={1}
                    imageurl={pageData.item.images_path}
                    onSlideChanged={ i => setDescriptionIndex(i) }
                />
            </div>

            <div className='case_flex'>
                <div className='case_conclusion'>
                    <div className='case_text_info'>{t('description')}</div>
                    <div className='case_text_block_bold'>
                        {pageData.item['description_' + user.language]}</div>
                </div>
            </div>

            <br />

            <BottomCaseNavigation pageData={pageData} />
        </div>
    </div>;
});

export default Ultrasound_Data_Entrie_Page;
