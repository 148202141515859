/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: index.js
 *  @description: Этот файл является частью клиентской стороны проекта. Точка входа в react приложение.
 *	@author: Горбас Александр Петрович
*/
import React, {createContext} from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import User_Store from './store/User_Store';
import Data_Store from './store/Data_Store';
import { ErrorBoundary } from './components/ErrorBoundary';

import './i18n';

export const Context = createContext(null)
const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript

root.render(
    <Context.Provider value={{
        user: new User_Store(),
        record: new Data_Store(),
    }}>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </Context.Provider>
);

