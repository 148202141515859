/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Ultrasound_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Ultrasound_Data_API.js
 *	@author: Белов Михаил Александрович
*/
import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ Ultrasound ]

//===========
// begin [ Ultrasound_Categories ]

export const fetchUltrasound_Categories = async (search, language) => {
    const {data} = await $host.get('api/ultrasound/ultrasound_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOneUltrasound_Categories = async (id) => {
    const {data} = await $host.get('api/ultrasound/ultrasound_categories/' + id)
    return data
}

// end [ Ultrasound_Categories ]
//===========

//-----------

//===========
// begin [ Ultrasound_Data ]

export const fetchUltrasound_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/ultrasound/ultrasound_data', {params: {
            search, language, categories_id, page, limit
        }})
    return data
}

export const fetchOneUltrasound_Data = async (id) => {
    const {data} = await $host.get('api/ultrasound/ultrasound_data/' + id)
    return data
}

// end [ Ultrasound_Data ]
//===========

//// end [ Ultrasound ]
////===========

//-----------