/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: AppRouter.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Установки для навигации по страницам приложения.
 *	@author: Горбас Александр Петрович
*/
import React, {useContext} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom'
import {authRoutes, publicRoutes} from '../routes';
import {LOGIN_ROUTE, START_ROUTE} from '../utils/consts';
import {Context} from '../index';
import {observer} from 'mobx-react-lite';

const AppRouter = observer( () => {
    const { user } = useContext(Context);

    return (
        <Routes>
            {user.isAuth && authRoutes.filter( ({ path }) => user.access_level.pages_denied.includes(path) ? false : true ).map(({path, Component}) =>
                <Route key={path} path={path} element={<Component />} />
            )}
            {publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={<Component />} />
            )}
            <Route path='*' element={user.isAuth ? <START_ROUTE /> : <Navigate replace to={LOGIN_ROUTE} />} />
        </Routes>
    );
});

export default AppRouter;
