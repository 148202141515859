/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Microbiology_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Microbiology_Data_API.js
 *	@author: Белов Михаил Александрович
*/
import { $host } from '../index';

//-----------

//===========
//// begin [ Microbiology ]

//===========
// begin [ Microbiology_Categories ]

export const fetchMicrobiology_Categories = async (search, language) => {
    const {data} = await $host.get('api/microbiology/microbiology_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOneMicrobiology_Categories = async (id) => {
    const {data} = await $host.get('api/microbiology/microbiology_categories/' + id)
    return data
}

// end [ Microbiology_Categories ]
//===========

//-----------

//===========
// begin [ Microbiology_Data ]

export const fetchMicrobiology_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/microbiology/microbiology_data', {params: {
            search, language, categories_id, page, limit
        }})
    return data
}

export const fetchOneMicrobiology_Data = async (id) => {
    const {data} = await $host.get('api/microbiology/microbiology_data/' + id)
    return data
}

// end [ Microbiology_Data ]
//===========

//// end [ Microbiology ]
////===========

//-----------