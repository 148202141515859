/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: User_Store.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит так называемые хранилища, сохраняющие своё состояние объекты при переходе по страницам атласа. User_Store.js
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import {makeAutoObservable} from "mobx";

export default class User_Store {
    constructor() {
        this._isAuth = false
        this._user = {}
        this._profile = {}
        this._counters = {};

        this._access_level = {}

        this._language = {}

        this._search = {}

        makeAutoObservable(this)
    }

//-----------

    setIsAuth(bool) {
        this._isAuth = bool
    }

    get isAuth() {
        return this._isAuth
    }

//-----------

    setCounters(c) {
        this._counters = c;
    }

    get counters() {
        return this._counters;
    }

//-----------

    setUser(user) {
        this._user = user
    }

    get user() {
        return this._user
    }

    setProfile(profile) {
        this._profile = profile;
    }

    get profile() {
        return this._profile;
    }

//-----------

    setAccess_level(data) {
        this._access_level = data
    }

    get access_level() {
        return this._access_level
    }

//-----------

    setLanguage(data) {
        this._language = data
    }

    get language() {
        return this._language
    }

//-----------

    setSearch(data) {
        this._search = data
    }

    get search() {
        return this._search
    }
}