/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Patanatomy_Data_Store.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит так называемые хранилища, сохраняющие своё состояние объекты при переходе по страницам атласа. Data Patanatomy_Data_Store.js
 *	@author: Горбас Александр Петрович
*/
import {makeAutoObservable} from "mobx";

export default class Patanatomy_Data_Store {
    constructor(setPage) {

        this._patanatomy_categories = []
        this._selectedPatanatomy_Categories = {}

        this._patanatomy_data = []

        this.setPage = setPage;

        makeAutoObservable(this)
    }

//-----------

//===========
// begin [ Patanatomy_Categories ]

    setPatanatomy_Categories(data) {
        this._patanatomy_categories = data
    }

    get patanatomy_categories() {
        return this._patanatomy_categories
    }

    setSelectedPatanatomy_Categories(data) {
        this.setPage(1)
        this._selectedPatanatomy_Categories = data
    }

    get selectedPatanatomy_Categories() {
        return this._selectedPatanatomy_Categories
    }

// end [ Patanatomy_Categories ]
//===========

//-----------

//===========
// begin [ Patanatomy_Data ]

    setPatanatomy_Data(data) {
        this._patanatomy_data = data
    }

    get patanatomy_data() {
        return this._patanatomy_data
    }

// end [ Patanatomy_Data ]
//===========

//-----------

}
