/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: PasswordRestorePage.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Файлы относящиеся к страницам профиля. Это страница восстановления пароля отвечает за логику восстановления пароля пользователя.
 *	@author: Белов Михаил Александрович
*/
import React, { useContext, useState, useEffect } from 'react';
import { NavLink, redirect, useLocation, useNavigate } from "react-router-dom";
import { LOGIN_ROUTE, REGISTRATION_ROUTE, PIMU_CATEGORIES_ROUTE, PASSWORD_RESTORE_ROUTE } from "../../utils/consts";
import { login, registration, restore, restoreCheckToken } from "../../http/User_API";
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { motion, AnimatePresence } from 'framer-motion';
import Spline from '@splinetool/react-spline';

const FormRestoreInitial = ({ t }) => {
    return <div>
        {t('enter_email')}
        <input type='email' className='input_form' name='email' required />
    </div>;
};

const FormRestorePasswordReset = ({ t }) => {
    return <div>
        {t('enter_new_password')}
        <input type='password' className='input_form' name='password' required />
    </div>;
};

//  Проверка правильности ввода электронного адреса.
const ValidateEmail = (email) => {
    if (!email)
        return;

    const whiteSpacesFound = (email.match(/\s/g) || []).length;
    if (whiteSpacesFound)
        return false;

    //  Должен быть один символ "@".
    const atSymbolPos = email.indexOf('@');
    const atSymbolsCount = (email.match(/\@/g) || []).length;
    if (atSymbolPos == -1 || atSymbolsCount != 1)
        return false;

    //  Должна быть точка, следующая после символа '@'.
    const dotPos = email.indexOf('.', atSymbolPos);
    if (dotPos == -1 || dotPos <= atSymbolPos)
        return false;

    //  После символа точки должно быть название домена верхнего уровня.
    const topDomain = email.substring(dotPos);
    if (!topDomain)
        return false;

    return true;
}

const PasswordRestorePage = observer( () => {
    const { t } = useTranslation();
    const location = useLocation();

    const [textIndex, setTextIndex] = useState(0);
    const texts = t('registration_title_text', { returnObjects: true });
    const variants = {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
    };

    const [ dummy, token ] = location.pathname.substring(1).split('/');
    const [ tokenInfo, setTokenInfo ] = useState({ valid: false, token: token });

    useEffect( () => {
        if (tokenInfo.token && !tokenInfo.valid)
            restoreCheckToken(tokenInfo.token).then( (result) => {
                setRestoreHint({ shown: true, text: result ? 'password_restore_process_description_2' : 'password_restore_wrong_token', danger: result === false });
                setTokenInfo({ valid: result, token: null });
            });
    }, [ tokenInfo ]);

    const [ restoreHint, setRestoreHint ] = useState({ shown: false, text: null, danger: false });
    //  В зависимости от валидности токена сброса - нужно либо отправить запрос на обновление пароля либо запрос на отправку ссылки для восстановления.
    const TriggerPasswordRestore = async (event) => {
        event.preventDefault();

        if (!tokenInfo.token && tokenInfo.valid)
            await SendPasswordReset(event);
        else
            await SendPasswordResetLink(event);
    };

    //  Запрос на отправку письма для сброса пароля.
    const SendPasswordResetLink = async (event) => {
        const requestData = {};
        new FormData(event.target).forEach( (v, k) => requestData[k] = v );

        const emailValidated = ValidateEmail(requestData.email);
        if (!emailValidated)
        {
            setRestoreHint({ shown: true, text: 'password_restore_wrong_email', danger: true });
            return;
        }

        try
        {
            const response = await restore({ email: requestData.email });
            if (!response)
                throw new Error();

            setRestoreHint({ shown: true, text: 'password_restore_process_description', danger: false })
        }catch (error)
        {
            setRestoreHint({ shown: true, text: 'password_restore_fetch_error', danger: true });
        }
    };

    //  Запрос на непосредственное обновление пароля.
    const SendPasswordReset = async (event) => {
        const requestData = {};
        new FormData(event.target).forEach( (v, k) => requestData[k] = v );

        try
        {
            const response = await restore({ token: token, password: requestData.password });
            if (!response)
                throw new Error(response.error);

            setRestoreHint({ shown: true, text: 'password_restore_success', danger: false });
        }catch (error)
        {
            setRestoreHint({ shown: true, text: 'password_restore_fetch_error', danger: true });
        }
    };

    return <div className='registration_page'>
        <div className='registration_grid'>
            <div className='registration_title'>
                <b>{t('start_page_title')}</b> • {t('navbar_title_restore_password')}
            </div>
            <div className='registration_title_text'>
                <AnimatePresence mode='wait'>
                    <motion.div
                        key={texts[textIndex]}
                        variants={variants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{ duration: 1 }}
                        onAnimationComplete={() => {
                            setTimeout(() => {
                                setTextIndex((textIndex + 1) % texts.length);
                            }, 3500); // Задержка перед переключением на следующий текст
                        }}
                    >
                        {texts[textIndex]}
                    </motion.div>
                </AnimatePresence>
            </div>
            <div className='spline_scene_demo_reg'>
                <Spline scene="https://prod.spline.design/94EWGQjymKY1dAjW/scene.splinecode" />
            </div>
            <div className="registration_form">
                <form onSubmit={TriggerPasswordRestore}>
                    <div className="registration_form_welcome">
                        {t('welcome_reg')}
                    </div>
                    <div className="registration_form_input">
                        {!tokenInfo.token && tokenInfo.valid ? <FormRestorePasswordReset t={t} /> : <FormRestoreInitial t={t} /> }
                        <div id='restore_hint' className="registration_form_hint" style={{ textAlign: 'justify', fontSize: '0.9rem', color: restoreHint.danger ? 'red' : 'green' }}>
                            {restoreHint.shown && t(restoreHint.text)}
                        </div>
                        <input className="authorization_button" disabled={restoreHint.shown && restoreHint.danger === false && !tokenInfo.valid && 'disabled'} style={{ margin: '50px auto' }} type='submit' value={t('restore_password')} />
                    </div>
                </form>
            </div>
        </div>
    </div>;
});

export default PasswordRestorePage;