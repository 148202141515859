/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: TestExtra_Data_Store.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит так называемые хранилища, сохраняющие своё состояние объекты при переходе по страницам атласа. Data TestExtra_Data_Store.js
 *	@author: Горбас Александр Петрович
*/
import {makeAutoObservable} from "mobx";

export default class TestExtra_Data_Store {
    constructor(setPage) {

        this._testExtra_categories = []
        this._selectedTestExtra_Categories = {}

        this._testExtra_data = []

        this.setPage = setPage;

        makeAutoObservable(this)
    }

//-----------

//===========
// begin [ TestExtra_Categories ]

    setTestExtra_Categories(data) {
        this._testExtra_categories = data
    }

    get testExtra_categories() {
        return this._testExtra_categories
    }

    setSelectedTestExtra_Categories(data) {
        this.setPage(1)
        this._selectedTestExtra_Categories = data
    }

    get selectedTestExtra_Categories() {
        return this._selectedTestExtra_Categories
    }

// end [ TestExtra_Categories ]
//===========

//-----------

//===========
// begin [ TestExtra_Data ]

    setTestExtra_Data(data) {
        this._testExtra_data = data
    }

    get testExtra_data() {
        return this._testExtra_data
    }

// end [ TestExtra_Data ]
//===========

//-----------

}
