/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Biology_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "паразитология". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории. Страница категорий биологии отвечает вывод категорий биологии, отображение меню поиска и основных подкатегорий.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import Biology_Categories_ButtonDisplay from "../../components/Biology/Biology_Categories_ButtonDisplay";
import '../../components/Sub_Categories_Page.css';
import { useTranslation } from "react-i18next";
import Search_Bar_biology from "../../components/Search_Bar_biology";
import { PreparePage } from '../../utils/PageDataPrepare.js';
import { useLocation } from 'react-router-dom';
import BottomCategoriesDisplay from '../../components/BottomCategoriesDisplay.jsx';

export const Biology_EntriesPerPage = 8;

const Biology_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, 0).then((data) => setPageData(data));
    }, [location, user, user.search])

    if (!pageData || !pageData.subCategory)
        return;

    return <div className='custom-background_menu'>
        <div id='example-destination' name='example-destination' className='sub_category_title'>
            <img className='page_title_icon' src='/icons/parazitology_icon.png' alt='page_title_icon'></img>{t('Bio_title')}
        </div>
        <div className='sub_category_flex'>
            <BottomCategoriesDisplay categories={pageData?.subCategory} matchingCategories={pageData?.search?.matches} />
        </div>

        <div className='sub_category_bottom_flex'>
            <div className='sub_category_rightpage_info'>
                <div className='sub_category_rightpage_info_2'>
                    <b>{t('Bio_title')}</b> - {t("Bio_title_desc")}
                    <br></br>
                    <hr></hr>
                    <b>{t('Medical_entomology')}</b> {t('Medical_entomology_desc')}
                    <br></br>
                    <br></br>
                    <b>{t('Helminthology')}</b> – {t('Helminthology_desc')}
                    <br></br>
                    <br></br>
                    <b>{t('Protozoology')}</b> – {t('Protozoology_desc')}
                </div>
            </div>
        </div>
    </div>;
});

export default Biology_Categories_Page;