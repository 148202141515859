/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_OptionsDefault_Table.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "экг". ECG_Data_OptionsDefault_Table.js
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович
*/
import React, { useContext, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import "./ECG_Data_Options_Table.css"
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import { Button, Collapse } from 'react-bootstrap'




const ECG_Data_OptionsDefault_Table = ({ data }) => {

    /* state hook for collapse */
    const [open, setOpen] = useState(false);

    const { user } = useContext(Context)
    const { t } = useTranslation();
    if (data.length === 0) {
        return (<div></div>);
    }

    let columnDictionary

    switch (user.language) {
        case 'ru':
            columnDictionary = {
                P_sec_ru: 'P, сек',
                PQ_sec: 'PQ, сек',
                QRS_sec: 'QRS, сек',
                AQRS_grad_ru: 'ось QRS, град',
                CHSS_yd_min: 'ЧСС, уд.мин',
                QT_kor_sec_ru: 'QTc, сек',
            }
            break;
        case 'en':
            columnDictionary = {
                P_sec_en: 'P, sec',
                PQ_sec: 'PQ, sec',
                QRS_sec: 'QRS, sec',
                AQRS_grad_en: 'Axis QRS, degree',
                CHSS_yd_min: 'Heart rate, bpm',
                QT_kor_sec_en: 'QTc, sec',
            }
            break;
        default:
            columnDictionary = {
                P_sec_ru: 'P, sec',
                PQ_sec: 'PQ, sec',
                QRS_sec: 'QRS, sec',
                AQRS_grad_ru: 'Axis QRS, degree',
                CHSS_yd_min: 'Heart rate, bpm',
                QT_kor_sec_ru: 'QTc, sec',
            }
    };


    const columnNames = Object.keys(columnDictionary);


    if (Array.isArray(data)) {
        return (
            <div className='table_ekg_dop_bd'>
                <div className='table_ekg_dop_name'>
                    <div className='table_header'></div>
                    <div className='table_collapse_button'
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                    >
                        {t("table_ekg_dop_name")}
                        <img className='collapse_img' src={open ? '/icons/collapse_closed.svg' : '/icons/collapse_open.svg'} alt="collapse icon"></img>
                    </div>
                    <Collapse in={open} >
                        <div id="example-collapse-text" className='table_ekg_normal_static'>
                            <div className="table_ekg_normal_static_parent_grid5">
                                <div className="tb_ekg_normal_1">
                                    <div className='tb_ekg_normal_column'>P</div>
                                    <div className='tb_ekg_normal_ed'>{t("sec")}</div>
                                    <div className='tb_ekg_normal_val'>{t("to")} 0.12</div>
                                </div>
                                <div className="tb_ekg_normal_2">
                                    <div className='tb_ekg_normal_column'>PQ</div>
                                    <div className='tb_ekg_normal_ed'>{t("sec")}</div>
                                    <div className='tb_ekg_normal_val'>0.12 - 0.2</div>
                                </div>
                                <div className="tb_ekg_normal_3">
                                    <div className='tb_ekg_normal_column'>QRS</div>
                                    <div className='tb_ekg_normal_ed'>{t("sec")}</div>
                                    <div className='tb_ekg_normal_val'>0.7 - 0.10</div>
                                </div>
                                <div className="tb_ekg_normal_4">
                                    <div className='tb_ekg_normal_column'>{t("Axis")} QRS</div>
                                    <div className='tb_ekg_normal_ed'>{t("degree")}</div>
                                    <div className='tb_ekg_normal_val'>{t("from")} -30 {t("to")} 90</div>
                                </div>
                                <div className="tb_ekg_normal_5">
                                    <div className='tb_ekg_normal_column'>{t("Heart_rate")}</div>
                                    <div className='tb_ekg_normal_ed'>{t("bpm")}</div>
                                    <div className='tb_ekg_normal_val'>60 - 100</div>
                                </div>
                                <div className="tb_ekg_normal_6">
                                    <div className='tb_ekg_normal_column'>QTc</div>
                                    <div className='tb_ekg_normal_ed'>{t("sec")}</div>
                                    <div className='tb_ekg_normal_val6'>0.34 - 0.45 {t("male")}</div>
                                    <div className='tb_ekg_normal_val6'>0.34 - 0.47 {t("female")}</div>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
                {/*
                {t("table_ekg_dop_name")}
                 <div className="table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                {columnNames.map((columnName) => (
                                    <th key={columnName}>
                                        {columnDictionary[columnName]}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((row, index) => (
                                <tr key={`row_${index}`}>
                                    {columnNames.map((columnName) => (
                                        <td key={`cell_${columnName}_${index}`}>
                                            {row[columnName] !== null ? row[columnName] : '\u00A0'}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div> */}
            </div >
        );
    }
    else {
        return (<div></div>);
    }
};

export default ECG_Data_OptionsDefault_Table;
