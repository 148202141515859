/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_EntriesList.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "экг". В этом файле находится компонент, отвечающий за вывод списка кейсов конкретной категории.
 *	@author: Манжос Геннадий Юрьевич,  Горбас Александр Петрович, Белов Михаил Александрович
*/
import { observer } from "mobx-react-lite";
import ECG_Data_EntriesItem from "./ECG_Data_EntriesItem";
import "./ECG_Data_EntriesList.css"
import { fetchGender } from "../../http/Data_API/Gender_Data_API";
import { useState, useEffect } from "react";

const ECG_Data_EntriesList = observer(({ pageData }) => {
    const [ genders, setGenders ] = useState(null);

    useEffect( () => {
        fetchGender().then( (genders) => setGenders(genders) );
    }, [ pageData ]);

    if (!pageData?.items?.rows || !genders)
        return;

    return <div className="ecg_list_flex">
        {pageData.items.rows.map( (item) => <ECG_Data_EntriesItem key={item.id} item={item} genders={genders} /> )}
    </div>;
});

export default ECG_Data_EntriesList;