/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ECG_Data_EntriesTable_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "экг". В этом файле находится содержимое страницы, которая выводится при переходе на страницу со списком кейсов, относящихся к конкретной категории.
 *	@author: Манжос Геннадий Юрьевич,  Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import Gender_Bar from "../../components/Gender_Bar";
import ECG_Data_EntriesList from "../../components/ECG/ECG_Data_EntriesList";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { useLocation } from "react-router-dom";
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import { PreparePage } from '../../utils/PageDataPrepare';
import Pages from '../../components/Pages';
import { useTranslation } from "react-i18next";
import "./ECG_Data_EntriesTable_Page.css";

const ECG_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { t } = useTranslation();
    const selectedGender = searchParams.get('gender');
    const selectedAges = searchParams.has('ages') ? searchParams.get('ages').split(',') : null;

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, 6, selectedGender == 3 ? null : selectedGender, null, selectedAges).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData || !pageData.items)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/ekg_icon.png' alt='page_title_icon'></img>{t('ECG_title')}
        </div>
        <div className='content_breadcrumbs'>
            <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
        </div>
        <div className='content_cases'>
            <div className='content_filters'>
                <Gender_Bar onGenderChanged={(gender) => { user.gender = gender; user.page = 1; }} onAgeChanged={ ({ min, max }) => { user.ages = [ min, max ]; user.page = 1; } } />
            </div>
            <div className='content_body'>
                <ECG_Data_EntriesList pageData={pageData} />
                <div className='content_Pages'>
                    <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
                </div>
            </div>
        </div>
    </>
});

export default ECG_Data_EntriesTable_Page;
