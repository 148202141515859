/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Pimu_Categories_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Pimu_Categories_Data_API.js
 *	@author: Горбас Александр Петрович
*/
import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
// begin [ Pimu_Categories ]

export const fetchPimu_Categories = async () => {
    const {data} = await $host.get('api/pimu_categories', )
    return data
}

export const fetchOnePimu_Categories = async (id) => {
    const {data} = await $host.get('api/pimu_categories/' + id)
    return data
}

// end [ Pimu_Categories ]
//===========

//-----------