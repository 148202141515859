/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: ProtectedImage.jsx
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. В этом файле находится компонент, отвечающий за вывод защищённого изображения для кейса.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import { useEffect, useState } from 'react';
import { fetchOneProtected_Data } from '../http/Data_API/Protected_Data_API';

//  Защищённое изображение.
//  Делает запрос к серверу, который возвращает base64 представление изображения.
//  При запросе через клиентское приложение, никаких доп. действий не требуется, т.к. браузер знает,
//  как работать с такими изображениями.
//  TODO: адаптировать стили или компонент Zoom для использования с canvas, после чего, можно сделать переменную 'usingCanvas' равной 'true'.
const ImageProtected = ({ url, className, usingCanvas = false }) => {

    const elementId = 'canvas_' + parseInt(Math.random() * 100_000_000);

    const HandleContextMenu = (event) => {
        event.preventDefault();
    };

    useEffect( () => {
        fetchOneProtected_Data(url).then( (image) => {

            if (!usingCanvas)
            {
                const img = document.getElementById(elementId);
                img.src = 'data:image/jpeg;base64,' + image.data;
                return;
            }

            const canvas = document.getElementById(elementId);
            if (!canvas)
                return;

            const context = canvas.getContext('2d');

            const decodedImg = new Image();
            decodedImg.src = 'data:image/jpeg;base64,' + image.data;

            decodedImg.addEventListener('load', () => {
                //  TODO: нормальный рассчёт фактора уменьшения для изображений - необходимо указывать максимальную длину и высоту и вписывать изображение в этот формат. Сейчас почему-то canvas не реагирует на это и изображение рисуется в каких-то своих единицах, а не пикселах.
                const maxWidth = 450;
                const maxHeight = 230;
                const ratio = Math.min(decodedImg.width / maxWidth, decodedImg.height / maxHeight);
                console.log(ratio, decodedImg.width / ratio, decodedImg.height / ratio);
                context.drawImage(decodedImg, 0, 0, decodedImg.width / ratio, decodedImg.height / ratio);
            });
        });
    }, [] );

    if (!url)
        return;

    if (usingCanvas)
        return <canvas id={elementId} onContextMenu={HandleContextMenu} className={className}></canvas>;
    else
        return <img id={elementId} onContextMenu={HandleContextMenu} className={className} />;
};

export default ImageProtected;