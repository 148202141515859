/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Radiology_Examination_Block.jsx
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "лучевая диагностика". Radiology_Examination_Block.jsx
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович, Манжос Геннадий Юрьевич
*/
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Context } from "../../index";

import Radiology_Images_Display from './Radiology_Images_Display';
import './Radiology_Examination_Block.css';
import SliderProtected from '../ProtectedImageSlider';

//  Блок с информацией об одном исследоваании, которое относится к обращению пациента.
//  Здесь необходимо вывести:
//  *   тип исследования,
//  *   описание,
//  *   заключение,
//  *   все изображения.
const Radiology_Examination_Block = observer(({ data }) => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const [descriptionIndex, setDescriptionIndex] = useState(0);

    if (!data)
        return;

    return <div className='radiology_examination_block'>
        <div className='radiology_examination_description'>
            <div className='title'>{t('radiology_description')}</div>
            <div className='text'>{data['description_' + user.language] ?? t('radiology_no_content')}</div>
        </div>

        {data.conclusion_ru && <div className='radiology_examination_conclusion'>
            <div className='title'>{t('radiology_conclusion')}</div>
            <div className='text'>{data['conclusion_' + user.language] ?? t('radiology_no_content')}</div>
        </div>}

        {data.treatment_ru && <div className='radiology_examination_treatment'>
            <div className='title'>{t('radiology_treatment')}</div>
            <div className='text'>{data['treatment_' + user.language] ?? t('radiology_no_content')}</div>
        </div>}

        {data.images_paths.length > 0 && <div className='radiology_examination_images'>
            <SliderProtected
                imagesPerView={1}
                imageurl={data.images_paths}
                onSlideChanged={ i => setDescriptionIndex(i) }
            />
        </div>}

{/*Отображение содержимого столбца 'images_' соответственно порядковому номеру изображения в поле images_paths*/}
        <div className='radiology_examination_image_description'>
            <div className='title'>{t('radiology_description')}</div>
            <div className='text'>
                {data['images_' + user.language][descriptionIndex] ?? t('radiology_no_content')}
            </div>
        </div>

{/* Временное отображение всего  содержимого столбца 'images_'*/}

        {/*{data.images_ru && <div className='radiology_examination_treatment'>*/}
            {/*<div className='title'>{t('radiology_treatment')}</div>*/}
            {/*<div className='text'>{data['images_' + user.language]}</div>*/}
        {/*</div>}*/}
    </div>;
});

export default Radiology_Examination_Block;