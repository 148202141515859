/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: TestExtra_Data_Images_Display.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. TestExtra В этом файле находится компонент, отвечающий за вывод всех картинок в кейсе.
 *	@author: Горбас Александр Петрович
*/
import React, {useContext} from 'react';
import TestExtra_Data_Image from "./TestExtra_Data_Image";

const TestExtra_Data_Images_Display =  ({imageurl}) => {
    const rows = [];

    const imageurldef = "/"

    rows.push(<div key={"space_image"} style={{width: "auto", height: 30}}></div>);
    rows.push(<div key={"image_name"} style={{width: "auto", height: "auto"}}>Изображения:</div>);

    for (let i = 0; i < imageurl.length; i++)
    {
        if (i>0)
        {
            rows.push(<div key={"images_space_" + i} style={{width: "auto", height: 10}}></div>);
        }
        rows.push(<TestExtra_Data_Image key={i} url={imageurldef+imageurl[i]}/>);
    }

    return (
        <div>{rows}</div>
    );
};
export default TestExtra_Data_Images_Display;