/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Radiology_Appeal_Data.jsx
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. Категория "лучевая диагностика". Компонент для отрисовки отдельного обращения пациента. Обращение может включать в себя несколько исследований.
 *	@author: Белов Михаил Александрович, Манжос Геннадий Юрьевич
*/
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Context } from "../../index";

import Radiology_Examination_Block from './Radiology_Examination_Block';

import './Radiology_Appeal_Block.css';

//  Блок с информацией об обращении пациента.
//  Здесь необходимо вывести:
//  *   номер обращения,
//  *   анамнез,
//  *   лаб. показатели,
//  *   лечение (если проводилось),
//  Также, необходимо вывести все относящиеся к этому обращению проведённые исследования.
const Radiology_Appeal_Block = observer(({ data }) => {
    const { user } = useContext(Context);
    const { t } = useTranslation();

    const [ activeTab, setActiveTab ] = useState(data?.examinations?.[0].id);

    if (!data)
        return;

    return <div className='radiology_appeal_block'>
        <div className='radiology_appeal_anamnesis'>
            <div className='title'>{t('radiology_anamnesis')}</div>
            <div className='text'>{data['anamnesis_' + user.language] ?? t('radiology_no_content')}</div>
        </div>

        {data.labdiag_ru && <div className='radiology_appeal_labdiag'>
            <div className='title'>{t('radiology_labdiag')}</div>
            <div className='text'>{data['labdiag_' + user.language] ?? t('radiology_no_content')}</div>
        </div>}
        {data.treatment_ru && <div className='radiology_appeal_treatment'>
            <div className='title'>{t('radiology_treatment')}</div>
            <div className='text'>{data['treatment_' + user.language] ?? t('radiology_no_content')}</div>
        </div>}

        <br />

        <div className='radiology_appeals_wrapper'>
            <div className='radiology_appeal_header_items'>
                {data?.examinations?.map( e => <div key={e.id} className={ `radiology_appeal_header_item ${e.id == activeTab ? 'active' : ''}` } onClick={ () => setActiveTab(e.id) }>{t('radiology_examination')}&nbsp;{t('radiology_examination_type_' + e.type)}</div> )}
            </div>
            {data?.examinations?.map( e => e.id == activeTab && <Radiology_Examination_Block key={e.id} data={e} />)}
        </div>

    </div>;
});

export default Radiology_Appeal_Block;