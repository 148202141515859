/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: LogOut.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Страница выхода из системы отвечает за выход из системы.
 *	@author: Манжос Геннадий Юрьевич
*/
import React, { useContext, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { LOGIN_ROUTE } from '../utils/consts';
import { processUserLogOut } from '../http/User_API';
import { Context } from "../index";

const LogOutPage = observer( () => {
    const { user } = useContext(Context);

    useEffect( () => {
        processUserLogOut().then( ({ result }) => {
            if (result)
            {
                user.setUser({});
                user.setIsAuth(false);

                localStorage.removeItem('token');
                window.location.replace(LOGIN_ROUTE);
            }
        });
    }, [] );
    return;
});

export default LogOutPage;