/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Endoscopy_Data_EntriesTable_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "эндоскопия". В этом файле находится содержимое страницы, которая выводится при переходе на страницу со списком кейсов, относящихся к конкретной категории.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import Endoscopy_Data_EntriesList from "../../components/Endoscopy/Endoscopy_Data_EntriesList";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import Pages from "../../components/Pages";
import { useLocation } from 'react-router-dom';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import { PreparePage } from '../../utils/PageDataPrepare';
import { Endoscopy_EntriesPerPage } from './Endoscopy_Categories_Page';

const Endoscopy_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const location = useLocation();
    const [pageData, setPageData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Endoscopy_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);


    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/endoscopy_icon.png' alt='page_title_icon'></img>{t("Endoscopy_title")}
        </div>
        <Container>
            <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
            <Endoscopy_Data_EntriesList categoryInfo={pageData} />
            <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
        </Container>
        ;</>
});

export default Endoscopy_Data_EntriesTable_Page;
