/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Ultrasound_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "ультразвуковая диагностика". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import '../../components/Sub_Categories_Page.css';
import InProcess from "../InProcess";
import BottomCategoriesDisplay from '../../components/BottomCategoriesDisplay';

export const Ultrasound_EntriesPerPage = 6;

const Ultrasound_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Ultrasound_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user, user.search]);

    if (!pageData || !pageData?.subCategory)
        return;

    return <div className='custom-background_menu'>
        <div className='sub_category_title'>
            <img className='page_title_icon' src='/icons/ultrasound_icon.png' alt='page_title_icon'></img>{t('ultrasound_title')}
        </div>
        <div className='sub_category_flex'>
            <BottomCategoriesDisplay categories={pageData?.subCategory} matchingCategories={pageData?.search?.matches} />
        </div>
        <div className='sub_category_bottom_flex'>
            <div className='sub_category_rightpage_info'>
                <div className='sub_category_rightpage_info_2'>
                    <InProcess />
                    {t('ultrasound_desc_main')}
                </div>
            </div>
        </div>
    </div>;
});

export default Ultrasound_Categories_Page;