/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: TestExtra_Data_Image.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит компоненты - части страниц, допускающие многократное использование в разных частях. TestExtra В этом файле находится компонент, отвечающий за вывод картинки из списка картинок в кейсе.
 *	@author: Горбас Александр Петрович
*/
import React from 'react';

const TestExtra_Data_Image = ({url}) => {

    console.log(url)
    
    return (
        <div> <img src={url} alt={"изображение_ЭКГ"}/> </div>
    );
};

export default TestExtra_Data_Image;