/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: TestExtra_Data_EntriesTable_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. TestExtra В этом файле находится содержимое страницы, которая выводится при переходе на страницу со списком кейсов, относящихся к конкретной категории.
 *	@author: Горбас Александр Петрович,
*/
import React, {useContext, useEffect} from 'react';
import {Card, Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Gender_Bar from "../../components/Gender_Bar";
import TestExtra_Data_EntriesList from "../../components/TestExtra/TestExtra_Data_EntriesList";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import Pages from "../../components/Pages";
import {fetchTestExtra_Data} from "../../http/Data_API/TestExtra_Data_API";
import {fetchGender} from "../../http/Data_API/Gender_Data_API";
import {useTranslation} from "react-i18next";

const TestExtra_Data_EntriesTable_Page = observer(() => {
    const {record} = useContext(Context)
    const {user} = useContext(Context)
    const { t } = useTranslation();
    let entriesTableName;

    console.log(record.pimu_categories)

    useEffect(() => {
        fetchGender().then(data => record.setGenders(data))
    }, [record])

    useEffect(() => {
        fetchTestExtra_Data(record.selectedGender.id, record.testExtra.selectedTestExtra_Categories.id, record.page, 20).then(data => {
            record.testExtra.setTestExtra_Data(data.rows)
            record.setTotalCount(data.count)
        })
    }, [record, record.page, record.testExtra.selectedTestExtra_Categories, record.selectedGender])

    entriesTableName = " " + record.selectedPimu_Categories["name_"+user.language] + " > " + record.testExtra.selectedTestExtra_Categories["name_"+user.language] + " "

    //console.log(dataSet_Details)

    return (
        <Container>
            <Card style={{height: 'auto'}} border={"light"}>
                <div className="text-black-50 mt-1">
                    <div>{entriesTableName}</div>
                </div>
            </Card>
            <Row className="mt-2">
                <Col md={9}>
                    <Gender_Bar/>
                    <TestExtra_Data_EntriesList/>
                    <Pages/>
                </Col>
            </Row>
        </Container>
    );
});

export default TestExtra_Data_EntriesTable_Page;
