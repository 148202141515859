/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Modelling_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Modelling_Data_API.js
 *	@author: Белов Михаил Александрович
*/
import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ Modelling ]

//===========
// begin [ Modelling_Categories ]

export const fetchModelling_Categories = async (search, language) => {
    const {data} = await $host.get('api/modelling/modelling_categories', {params: {
            search, language
        }});
    return data;
}

// end [ Modelling_Categories ]
//===========

//-----------

//===========
// begin [ Modelling_Data ]

export const fetchModelling_Data = async (search, language, categories_id, page, limit) => {
    const {data} = await $host.get('api/modelling/modelling_data', {params: {
            search, language, categories_id, page, limit
        }});
    return data;
}

export const fetchOneModelling_Data = async (id) => {
    const {data} = await $host.get('api/modelling/modelling_data/' + id);
    return data;
}

// end [ Modelling_Data ]
//===========

//// end [ Modelling ]
////===========

//-----------