/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: KLD_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "клд". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import '../../components/Categories_Page.css';
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';

export const KLD_EntriesPerPage = 6;

const KLD_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, KLD_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user, user.search]);

    if (!pageData || !pageData?.subCategory)
        return;

    return <div className='page_content'>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/kld_icon.png' alt='page_title_icon'></img>{t('Clinical_lab_diagnistics')}
        </div>
        <div className='page_content_parent'>
            <div className='page_content_flex'>
                <SidebarCategoriesDisplay categories={pageData.subCategory} matchingCategories={pageData.search?.matches} />
            </div>
            <div className='page_content_rightpage_block'>
                <div className='Search_bar_block'>
                    <Search_Bar searchSucceeded={pageData.search?.succeeded} />
                </div>
                <div className='page_content_rightpage'>
                    <div className='page_content_title_info'>
                        {t('kld_description_main')}
                        <br></br>
                        <br></br>
                        {t('kld_desc_1')}
                        <br></br>
                        <br></br>
                        {t('kld_desc_2')}
                        <br></br>
                        <br></br>
                        {t('kld_desc_3')}
                        <br></br>
                        <br></br>
                        {t('kld_desc_4')}
                        <br></br>
                        <br></br>
                        {t('kld_desc_5')}
                    </div>
                </div>
            </div>

        </div>
    </div>;
});

export default KLD_Categories_Page;