/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: AccountPage.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Файлы относящиеся к страницам сайта "мой профиль". Страница профиля пользователя отвечает за логику отображения и обновления информации о пользователе.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import { observer } from 'mobx-react-lite';
import { Context } from '../../index';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    check,
    passwordUpdate,
    profileUpdate,
    getRoles,
    getMyUsers,
    registerControlledUser,
    updateControlledUser,
    deleteControlledUser,
    resetPasswordControlledUser,
    registerControlledUserEnMasse,
    prepareUsersExportList
} from '../../http/User_API';
import { fetchTesting_UserData } from '../../http/Data_API/Testing_Data_API';

import universities from '../../utils/university.json';
import specialties from '../../utils/specialty.json';

import './AccountPage.css';
import { redirect, useNavigate, useResolvedPath } from 'react-router-dom';

const ProfileInformationBlock = ({ t, user }) => {
    if (!user)
        return;

    const userUniversity = user.profile?.profile?.university ? universities.find( ({ value }) => value == user.profile?.profile?.university )?.label ?? user.profile.profile.university : null;
    const userSpecialty = user.profile?.profile?.specialty ? specialties.find( ({ value }) => value == user.profile?.profile?.specialty )?.label ?? user.profile.profile.specialty : null;

    return <div className='profile-info'>
        <div className='heading'>
            { user.profile.profile.firstName && user.profile.profile.lastName ?
                <>{user.profile.profile.firstName}&nbsp;{user.profile.profile.lastName}</> :
                <>{t('profile_info_login')}&nbsp;&laquo;{user.profile.email}&raquo;</>
            }
        </div>
        {user.profile.email && user.profile.email.indexOf('@') != -1 ? <p>
            <span className='text-body-secondary'>{t('profile_info_email')}:</span>&nbsp;{user.profile.email}
        </p> : <></> }
        <div className='row'>
            <div className='col-3 text-body-secondary'>
                {t('profile_edit_name')}:
            </div>
            <div className='col-auto'>
                {user.profile.profile?.firstName?.length ? user.profile.profile?.firstName : t('profile_info_entry_unspecified')}
            </div>
        </div>
        <div className='row'>
            <div className='col-3 text-body-secondary'>
                {t('profile_edit_surname')}:
            </div>
            <div className='col-auto'>
                {user.profile.profile?.lastName?.length ? user.profile.profile?.lastName : t('profile_info_entry_unspecified')}
            </div>
        </div>
        <div className='row'>
            <div className='col-3 text-body-secondary'>
                    {t('profile_info_register_date')}:
            </div>
            <div className='col-auto'>
                {user.profile?.createdAt instanceof Date ? user.profile.createdAt.toLocaleString(user.language) : new Date(user.profile?.createdAt).toLocaleString(user.language)}
            </div>
        </div>
        <div className='row'>
            <div className='col-3 text-body-secondary'>
                {t('profile_info_role')}:
            </div>
            <div className='col-auto'>
                {t(user.profile?.roleInfo?.title)}
            </div>
        </div>
        <div className='group university'>
            <p className='heading'>
                {t('profile_info_university')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_university')}:</span>&nbsp;{userUniversity ?? t('profile_info_entry_unspecified')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_edit_faculty')}:</span>&nbsp;{user.profile.profile?.faculty ?? t('profile_info_entry_unspecified')}
            </p>
            {user.profile.role == 'teacher' &&
            <p>
                <span className='text-body-secondary'>{t('profile_info_specialty')}:</span>&nbsp;{userSpecialty ?? t('profile_info_entry_unspecified')}
            </p> }
            {user.profile.role == 'teacher' &&
            <p>
                <span className='text-body-secondary'>{t('profile_info_position')}:</span>&nbsp;{user.profile?.profile?.position ?? t('profile_info_entry_unspecified')}
            </p> }
        </div>
        {user.profile?.profile?.moodle && <div className='group moodle'>
            <p className='heading'>
                {t('profile_info_moodle')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_moodle_client')}:</span>&nbsp;{user.profile?.profile?.moodle?.clientdescription ?? t('profile_info_entry_unspecified')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_login')}:</span>&nbsp;{user.profile?.profile?.moodle?.username ?? t('profile_info_entry_unspecified')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_moodle_country')}:</span>&nbsp;{user.profile?.profile?.moodle?.country ?? t('profile_info_entry_unspecified')}
            </p>
            <p>
                <span className='text-body-secondary'>{t('profile_info_moodle_lang')}:</span>&nbsp;{user.profile?.profile?.moodle?.language ?? t('profile_info_entry_unspecified')}
            </p>
        </div>}
    </div>;
};

const DemoAccessDescription = ({ t, user, location }) => {
    return <>
        <p className='fs-5 text-body-secondary'>
            {t('profile_subscription_demo_description')}
        </p>
        <div className='row row-cols-1 row-cols-md-2 mb-3 text-center'>
            <div className='col'>
                <div className='card mb-4 rounded-3 shadow-sm'>
                    <div className='card-header py-3'>
                        <h4 className='my-0 fw-normal'>{t('profile_subscription_demo_version')}</h4>
                    </div>
                    <div className='card-body'>
                        <h1 className='card-title pricing-card-title'>
                            {t('profile_subscription_free')}
                        </h1>
                        <ul className='list-unstyled mt-3 mb-4'>
                            <li>{t('profile_subscription_comparison_all_categories')}</li>
                            <li>{t('profile_subscription_some_cases')}</li>
                            <li>&nbsp;</li>
                        </ul>
                        <button className='w-100 btn btn-lg btn-outline-secondary disabled' type='button'>{t('profile_subscription_current')}</button>
                    </div>
                </div>
            </div>
            <div className='col'>
                <div className='card mb-4 rounded-3 shadow-sm border-primary'>
                    <div className='card-header py-3 text-bg-primary border-primary'>
                        <h4 className='my-0 fw-normal'>{t('profile_subscription_full_version')}</h4>
                    </div>
                    <div className='card-body'>
                        <h1 className='card-title pricing-card-title'>
                            1₽
                            <small className='text-body-secondary fw-light'>/{t('profile_subscription_pricing_year')}</small>
                        </h1>
                        <ul className='list-unstyled mt-3 mb-4'>
                            <li>{t('profile_subscription_comparison_all_categories')}</li>
                            <li>{t('profile_subscription_comparison_all_cases')}</li>
                            <li>{t('profile_subscription_comparison_extravaganza')}</li>
                        </ul>
                        <button onClick={ () => location('/demo_version') } className='w-100 btn btn-lg btn-primary' type='button'>{t('profile_subscription_request_full')}</button>
                    </div>
                </div>
            </div>
        </div>

        <h2 className='display-6 text-center mb-4'>{t('profile_subscription_comparison_title')}</h2>
        <div className='table-responsive'>
            <table className='table text-center'>
                <thead>
                    <tr>
                        <th></th>
                        <th>{t('profile_subscription_demo_title')}</th>
                        <th>{t('profile_subscription_full_title')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className='text-start' scope='row'>{t('profile_subscription_comparison_all_categories')}</th>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                            </svg>
                        </td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                            </svg>
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start' scope='row'>{t('profile_subscription_comparison_all_cases')}</th>
                        <td></td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                            </svg>
                        </td>
                    </tr>
                    <tr>
                        <th className='text-start' scope='row'>{t('profile_subscription_comparison_extravaganza')}</th>
                        <td></td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                            </svg>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>;
};

const FullAccessDescription = ({ t, user }) => {
    return <>
        <br />
        {t('profile_subscription_full_description', { returnObjects: true }).map( (v, i) => <p key={i} dangerouslySetInnerHTML={{ __html: v }}></p> )}
    </>;
};

const ProfileSubscriptionBlock = ({ t, user, location }) => {
    if (!user)
        return;

    const isDemo = user.access_level.demo_access;

    return <div>
        <p className='heading'>{t('profile_subscription_title')}</p>
        { isDemo ? <DemoAccessDescription t={t} user={user} location={location} /> : <FullAccessDescription t={t} user={user} /> }
    </div>;
};

const PasswordUpdateBlock = ({ t, user, error, setError }) => {
    if (!user)
        return;

    //  Обработка обновления пароля.
    const UpdatePassword = async (event) => {
        event.preventDefault();

        const requestData = {};
        new FormData(event.target).forEach( (v, k) => requestData[k] = v );

        setError({ status: null, message: null, where: null });

        try
        {
            if (!requestData.current || !requestData.new)
                throw new Error('profile_edit_error_no_password');

            if (requestData.current == requestData.new)
                throw new Error('profile_edit_error_same_password');

            const result = await passwordUpdate({ passwordCurrent: requestData.current, passwordNew: requestData.new });

            setError({ status: result.result, message: result.message, where: 'password' });
        }catch (error)
        {
            setError({ status: false, message: error.message, where: 'password' });
        }
    };

    return <div>
        <form onSubmit={UpdatePassword}>
            <div className='form-floating mb-3'>
                <h4>{t('profile_edit_password_update_title')}</h4>
            </div>
            <div className='form-floating mb-3'>
                <input type='password' name='current' className='form-control' required />
                <label>{t('profile_edit_password_current')}</label>
            </div>
            <div className='form-floating mb-3'>
                <input type='password' name='new' className='form-control' required />
                <label>{t('profile_edit_password_new')}</label>
            </div>
            <div className='form-floating mb-3'>
                {error.message && error.where == 'password' && <div className={ `fs-6 alert alert-${error.status ? 'success' : 'danger'}` }>{t(error.message)}</div>}
            </div>
            <div className='form-floating'>
                <button className='btn btn-outline-primary' type='submit'>{t('profile_edit_update')}</button>
            </div>
        </form>
    </div>;
};

const ProfileUpdateBlock = ({ t, user, error, setError }) => {
    if (!user)
        return;

    //  Обработка обновления информации профиля.
    const UpdateProfile = async (event) => {
        event.preventDefault();

        const requestData = {};
        new FormData(event.target).forEach( (v, k) => requestData[k] = v );

        setError({ status: null, message: null, where: null });

        try
        {
            const result = await profileUpdate({ profile: requestData });

            setError({ status: result.result, message: result.message, where: 'profile' });
        }catch (error)
        {
            setError({ status: false, message: error.message, where: 'profile' });
        }
    };

    return <div>
        <form onSubmit={UpdateProfile}>
            <div className='form-floating mb-3'>
                <h4>{t('profile_edit_bio_title')}</h4>
            </div>
            <div className='form-floating mb-3'>
                <input type='text' name='firstName' className='form-control' defaultValue={user.profile.profile?.firstName} required />
                <label>{t('profile_edit_name')}</label>
            </div>
            <div className='form-floating mb-3'>
                <input type='text' name='lastName' className='form-control' defaultValue={user.profile.profile?.lastName} required />
                <label>{t('profile_edit_surname')}</label>
            </div>
            {user.profile.role == 'university' && <div className='form-floating mb-3'>
                <select className='form-select' name='university' defaultValue={user.profile.profile?.university} required>
                    {universities.map( (u) => <option key={u.value} value={u.value}>{u.label}</option>)}
                </select>
                <label>{t('profile_edit_university')}</label>
            </div>}
            {user.profile.role == 'student' && <div className='form-floating mb-3'>
                <input className='form-control' name='faculty' defaultValue={user.profile?.profile?.faculty} type='text' />
                <label>{t('profile_edit_faculty')}</label>
            </div>}
            <div className='form-floating mb-3'>
                {error.message && error.where == 'profile' && <div className={ `fs-6 alert alert-${error.status ? 'success' : 'danger'}` }>{t(error.message)}</div>}
            </div>
            <div className='form-floating'>
                <button className='btn btn-outline-primary' type='submit'>{t('profile_edit_save')}</button>
            </div>
        </form>
    </div>;
};

const ProfileEditBlock = ({ t, user, error, setError }) => {
    if (!user)
        return;

    return <div>
        {
            //<PasswordUpdateBlock t={t} user={user} error={error} setError={setError} />
        }
        <ProfileUpdateBlock t={t} user={user} error={error} setError={setError} />
    </div>;
};

const ProfileMyUsersBlock = ({ t, user, error, setError }) => {
    const [ roles, setRoles ] = useState([]);
    const [ users, setUsers ] = useState([]);
    const [ editUser, setEditUser ] = useState(null);
    const [ search, setSearch ] = useState({ type: null, text: null });

    useEffect( () => {
        //  Запрос списка доступных ролей и непосредственных пользователей, где текущий пользователь является управляющим (родителем).
        const RequestRoles = async () => {
            const rolesList = await getRoles({ can_select: true });
            if (rolesList?.roles.length)
                setRoles(rolesList.roles);
        };

        const RequestUsers = async () => {
            const usersList = await getMyUsers(user.profile.id);
            if (usersList?.users.length)
                setUsers(usersList.users);
        };

        RequestRoles();
        RequestUsers();
    }, [] );

    //  Запрос на создание нового пользователя.
    const CreateControlledUSer = async (event) => {
        event.preventDefault();

        try
        {
            const requestData = {};
            new FormData(event.target).forEach( (v, k) => requestData[k] = v );

            if (!requestData.email)
                throw new Error(`Необходимо указать e-mail!`);

            if (!requestData.firstName)
                throw new Error(`Необходимо указать Имя!`);

            if (!requestData.lastName)
                throw new Error(`Необходимо указать Фамилию!`);

            if (!requestData.role)
                throw new Error(`Необходимо выбрать роль!`);

            const response = await registerControlledUser(requestData);
            if (!response)
                throw new Error(`Не удалось выполнить запрос`);

            if (!response?.result || !response?.user)
                throw new Error(response?.message ?? 'Не удалось создать пользователя');

            setError({ result: true, message: null, where: null });
            setUsers( (prev) => {
                return [
                    ...prev,
                    response.user,
                ];
            });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usercreate',
            });
        }
    };

    //  Запрос на удаление пользователя, контроль над которым осуществляется другим пользователем.
    const DeleteControlledUser = async (event) => {
       /* if (!confirm(t('profile_myusers_confirm_delete')))
            return; */

        try
        {
            const requestData = {
                id: event.target.dataset.userid,
            };

            const response = await deleteControlledUser(requestData);
            if (!response)
                throw new Error('Не удалось выполнить запрос');

            if (!response?.result)
                throw new Error(response?.message ?? 'Не удалось удалить пользователя');

            setError({ result: true, message: null, where: null });
            setUsers( (prev) => {
                return prev.filter( ({ id }) => id != requestData.id );
            });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usercreate',
            });
        }
    };

    //  Запрос на обновление информации о пользователе.
    const UpdateControlledUserInfo = async (event) => {
        event.preventDefault();

        try
        {
            const requestData = {};
            new FormData(event.target).forEach( (v, k) => requestData[k] = v );
            requestData.id = editUser.id;

            if (!requestData.email)
                throw new Error(`Необходимо указать e-mail!`);

            if (!requestData.firstName)
                throw new Error(`Необходимо указать Имя!`);

            if (!requestData.lastName)
                throw new Error(`Необходимо указать Фамилию!`);

            if (!requestData.role)
                throw new Error(`Необходимо выбрать роль!`);

            const response = await updateControlledUser(requestData);
            if (!response)
                throw new Error(`Не удалось выполнить запрос`);

            if (!response?.result || !response?.user)
                throw new Error(response?.message ?? 'Не удалось обновить пользователя');

            setError({ result: true, message: null, where: null });

            setEditUser(null);

            event.target.reset();

            setUsers( (prev) => {
                return [
                    ...prev.map( (u) => u.id == response.user.id ? response.user : u )
                ];
            });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usercreate',
            });
        }
    };

    //  Запрос на сброс пароля.
    const PasswordResetRequest = async (event) => {
        try
        {
            const requestData = {
                id: editUser.id,
            };

            const response = await resetPasswordControlledUser(requestData);
            if (!response)
                throw new Error(`Не удалось выполнить запрос`);

            if (!response?.result)
                throw new Error(response?.message ?? 'Не удалось сбросить пароль пользователя');

            setError({ result: true, message: null, where: null });

            setEditUser(null);
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usercreate',
            });
        }
    };

    const MassCreateControlledUsers = async (event) => {
        try
        {
            event.preventDefault();

            const requestData = {
                usersnumber: 0,
                role: null,
            };

            new FormData(event.target).forEach( (v, k) => requestData[k] = v );

            requestData.usersnumber = Number(requestData.usersnumber);

            if (requestData.usersnumber <= 0)
                throw new Error(`Количество пользователей должно быть больше нуля!`);

            if (requestData.usersnumber >= user.profile.profile.usersleft)
                throw new Error(`Количество пользователей должно быть меньше максимально допустимого!`);

            const response = await registerControlledUserEnMasse(requestData);
            if (!response)
                throw new Error(`Не удалось выполнить запрос`);

            if (!response?.result)
                throw new Error(response?.message ?? 'Не удалось создать пользователей');

            window.location.reload();

            setError({ result: true, message: null, where: null });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'usersmasscreate'
            })
        }
    };

    const UsersExportList = async (event) => {
        try
        {
            event.preventDefault();

            const response = await prepareUsersExportList();

            //  React не даёт открыть ссылку вида 'blob:' из скрипта.
            //  Потому, нужно 'эмулировать' такое поведение - создать элемент-ссылку на 'blob' и "нажать" на неё.
            const href = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'result.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setError({ result: true, message: null, where: null });
        }catch (error)
        {
            setError({
                result: false,
                message: error.message,
                where: 'list'
            });
        }
    };

    const HandleUserFilters = (val) => {
        if (search.type == null || search.text == null || search.text == '')
            return true;

        if (search.type == 'search')
            return val.email?.includes(search.text) || val.profile?.firstName?.includes(search.text) || val.profile?.lastName?.includes(search.text);

        if (search.type == 'role')
            return val.role == search.text;

        return false;
    };

    return <div className='users_block'>
        <p className='heading'>{t('profile_myusers_title')}</p>
        <p>{t('profile_myusers_hint')}</p>

        { user?.profile?.profile?.usersleft > 0 ? <div className='addnew_block mb-4'>
            <form className='form mt-2' onSubmit={editUser ? UpdateControlledUserInfo : CreateControlledUSer}>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' defaultValue={editUser?.email} name='email' required />
                    <label className='form-label'>{t('profile_info_email')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' defaultValue={editUser?.profile?.firstName} name='firstName' required />
                    <label className='form-label'>{t('profile_edit_name')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' defaultValue={editUser?.profile?.lastName} name='lastName' required />
                    <label className='form-label'>{t('profile_edit_surname')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <input type='text' className='form-control' defaultValue={editUser?.profile?.faculty} name='faculty' required />
                    <label className='form-label'>{t('profile_edit_faculty')}</label>
                </div>
                <div className='form-floating mb-2'>
                    <select className='form-select' name='role' defaultValue={editUser?.role} required>
                        {roles.map( (role) => <option key={role.id} value={role.id}>{t(role.title)}</option>)}
                    </select>
                    <label className='form-label'>{t('profile_info_role')}</label>
                </div>
                {!error.result && error.message && error.where == 'usercreate' && <div className='alert alert-danger fs-6'>
                    <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                    <span>{error.message}</span>
                </div>}
                <div className='d-flex justify-content-between'>
                    <div className='form-floating'>
                        <div className='input-group'>
                            <button type='submit' className='btn btn-primary'>{editUser ? t('profile_edit_save') : t('profile_edit_create')}</button>
                            {editUser && <button type='button' className='btn btn-danger' onClick={PasswordResetRequest}>{t('profile_myusers_resetpassword')}</button>}
                            {editUser && <button type='button' className='btn btn-secondary' onClick={ (e) => { setEditUser(null); setError({ result: true, message: null, where: null }) } }>{t('profile_myusers_cancel')}</button>}
                        </div>
                    </div>
                    <div className='p-2 text-secondary fs-6'>
                        {t('profile_myusers_create_limit_hint')?.replace(/\%\d\%/, (s) => user?.profile?.profile?.usersleft ?? 0 )}
                    </div>
                </div>
            </form>
        </div> : <div className='mb-5 mt-4'>
            <div className='alert alert-danger'>
                {t('profile_myusers_create_limit_hint')?.replace(/\%\d\%/, (s) => user?.profile?.profile?.usersleft ?? 0 )}
            </div>
        </div> }

        <div className='mass_create_field'>
            <p>{t('profile_myusers_masscreate')}</p>
            <form className='form mt-2' onSubmit={MassCreateControlledUsers}>
                <div className='row'>
                    <div className='col'>
                        <input type='number' className='form-control' name='usersnumber' placeholder={t('profile_myusers_masscreate_number')} required />
                    </div>
                    <div className='col'>
                        <select className='form-select' name='role' required>
                            {roles.map( (role) => <option key={role.id} value={role.id}>{t(role.title)}</option> )}
                        </select>
                    </div>
                </div>

                {!error.result && error.message && error.where == 'usersmasscreate' && <div className='mt-2 alert alert-danger fs-6'>
                    <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                    <span>{error.message}</span>
                </div>}

                <div className='row mt-2'>
                    <div className='col'>
                        <input className='btn btn-primary' type='submit' value={t('profile_edit_create')} />
                    </div>
                </div>
            </form>
        </div>

        <div className='users_list_filter mt-5 mb-2'>
            <div className='row'>
                <div className='col'>
                    <div className='input-group'>
                        <div className='input-group-text'>{t('search_block_button')}</div>
                        <input type='text' className='form-control' placeholder={t('profile_myusers_search_hint')} required onKeyDown={ event => event.key == 'Enter' && setSearch({ text: event.target.value != '' ? event.target.value : null, type: 'search' }) } />
                        {search.text != null && search.type == 'search' && <button className='btn btn-sm btn-outline-secondary' onClick={ event => setSearch({ type: null, text: null }) }>сброс</button>}
                    </div>
                </div>
                <div className='col-auto'>
                    <div className='input-group'>
                        <div className='input-group-text'>{t('profile_myusers_filter_role')}</div>
                        <select className='form-select' onChange={ event => setSearch({ text: event.target.value, type: 'role' }) }>
                            <option value=''>Любая</option>
                            {roles.map( (role) => <option key={role.id} value={role.id}>{t(role.title)}</option> )}
                        </select>
                    </div>
                </div>
                <div className='col-auto'>
                    <button className='btn btn-outline-secondary' onClick={UsersExportList}>{t('profile_myusers_export')}</button>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    {!error.result && error.where == 'list' && <div className='mt-2 alert alert-danger fs-6'>
                        <h4 className='alert-heading'>{t('generic_title_error')}</h4>
                        <span>{error.message}</span>
                    </div>}
                </div>
            </div>
        </div>

        <table className='table table-striped table-hover'>
            <thead>
                <tr>
                    <th>{t('profile_info_email')}</th>
                    <th>{t('profile_edit_name')},&nbsp;{t('profile_edit_surname')}</th>
                    <th>{t('profile_info_role')}</th>
                    <th>{t('profile_myusers_actions_buttons')}</th>
                </tr>
            </thead>
            <tbody>
                {!users.length && <tr><td colSpan={4} className='text-center p-4'>{t('profile_myusers_nousers')}</td></tr>}
                {users.filter(HandleUserFilters).map( (user) => <tr key={user.id}>
                    <td>{user.email}</td>
                    <td>{user.profile.firstName}&nbsp;{user.profile.lastName}</td>
                    <td>{t(roles.find( ({ id }) => id == user.role )?.title)}</td>
                    <td>
                        <div className='input-group'>
                            <button data-userid={user.id} onClick={ () => { setEditUser(user); setError({ result: true, message: null, where: null }); } } className='btn btn-sm btn-outline-secondary'>{t('profile_myusers_edit')}</button>
                            <button data-userid={user.id} onClick={DeleteControlledUser} className='btn btn-sm btn-outline-danger'>{t('profile_myusers_delete')}</button>
                        </div>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>;
};

const ProfilePassedQuizBlock = ({ t, user }) => {
    const [ quiz, setQuiz ] = useState([]);

    useEffect( () => {
        fetchTesting_UserData(user.profile.id).then( t => {
            setQuiz(t);
        });
    }, [] );

    const QuizQuestionResult = ({ row, qid, answers }) => {
        return <div className='row' key={row.id}>
            <div className='title'>{row['question_' + user.language]}</div>
            <div className='answers'>
                <ul>
                    {row['answer_' + user.language].split('|').map( (a, i) => <li key={i}>{a}&nbsp;{row.true_answer.split('|').map(Number).includes(i + 1) ? t('quiz_correct_short') : t('quiz_incorrect_short')}&nbsp;{answers[qid]?.includes(i + 1) && t('quiz_chosen_short')}</li> )}
                </ul>
            </div>
        </div>;
    };

    const ResultMark = (q) => {
        if (!q)
            return;

        let correctAnswers = 0;

        q.answers.map((a, i) => {
            const thisQuestionCorrectAnswers = q.quizid[i].true_answer.split('|').map(Number);
            const correct = a.reduce((prev, curr, i) => prev += thisQuestionCorrectAnswers.includes(curr) ? 1 : 0, 0);

            correctAnswers += correct == thisQuestionCorrectAnswers.length ? 1 : 0;
        });

        const percentCorrect = ((correctAnswers / q.quizid.length) * 100);
        let resultMark = 0;

        //  Оценка, на основании процента правильных ответов.
        if (percentCorrect <= 70)
            resultMark = 2;

        if (percentCorrect > 70 && percentCorrect <= 79)
            resultMark = 3;

        if (percentCorrect > 80 && percentCorrect <= 89)
            resultMark = 4;

        if (percentCorrect >= 90)
            resultMark = 5;

        return `${resultMark} (${percentCorrect}%)`;
    };

    return <div className='testing_results'>
        {quiz.length == 0 && <div>
                <div className='heading'>
                    {t('profile_quiz_noquiz')}
                </div>
                {t('profile_quiz_noquiz_hint')}
        </div>}

        {quiz.map( (q) => <div key={q.id} className='entry'>
            <div className='head'>
                <div className='title_and_date'>
                    <div className='title'>
                        {t('quiz_type_' + q.mode)}&nbsp;&ndash;&nbsp;{q.category['name_' + user.language]}
                    </div>
                    <div className='date'>
                        {new Date(q.createdAt).toLocaleString(user.language == 'ru' ? 'ru-RU' : 'en-US')}
                    </div>
                </div>
            </div>
            <div className='data'>
                {t('quiz_correct_short')}&nbsp;{q.correct}/{q.quizid.length}
                <br />
                {t('quiz_mark')}:&nbsp;{ResultMark(q)}
            </div>
        </div>)}
    </div>;
};

const AccountPage = observer( () => {
    const { t } = useTranslation();
    const { user } = useContext(Context);
    const [ activeBlock, setActiveBlock ] = useState('information');
    const [ error, setError ] = useState({ result: null, message: null, where: null });
    const location = useNavigate();

    const PossibleProfileBlockId = [
        'information',
        'edit',
        'subscription',
        'myusers',
        'quizzes'
    ];

    const ToggleProfileContent = async (event) => {
        const targetId = event.target.dataset?.targetId;
        if (!targetId)
            return;

        const data = await check();
        user.setProfile(data.user);
        setError({ result: null, message: null, where: null });
        setActiveBlock(targetId);

        if (!window.location.hash)
            window.location.hash = targetId;
        else
            if (window.location.hash != targetId)
                window.location.hash = targetId;
    };

    if (!user.profile?.profile)
    {
        location('/');
        return;
    }

    //  Если в пути было передано желаемое ID блока профиля, то переключиться туда.
    useEffect( () => {
        const targetHash = window.location?.hash ? window.location.hash.substring(1) : null;

        if (targetHash && PossibleProfileBlockId.includes(targetHash))
            ToggleProfileContent({ target: { dataset: { targetId: targetHash } } });
    }, []);

    const roleHasAccessTo = user.profile?.roleInfo?.access_allowed ?? [];

    return <div className='profile'>
        <div className='header'>
            <img src='/icons/grey_logo_pimu.png' width={100} height={102} alt='Profile header' />
            <span className='title'>{t('profile_title')}</span>
            <div className='small'>{t('profile_title_description')}</div>
        </div>
        <div className='content'>
            <div className='menu'>
                <button data-target-id='information' className={activeBlock == 'information' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_info')}</button>
                {!user.profile?.profile.moodle && <button data-target-id='edit' className={activeBlock == 'edit' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_edit')}</button> }
                <button data-target-id='subscription' className={activeBlock == 'subscription' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_subscription')}</button>
                {roleHasAccessTo.includes('control_my_users') && <button data-target-id='myusers' className={activeBlock == 'myusers' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_myusers')}</button>}
                <button data-target-id='quizzes' className={activeBlock == 'quizzes' ? 'active' : ''} onClick={ToggleProfileContent}>{t('profile_sidebar_quiz')}</button>
            </div>
            <div className='block'>
                { activeBlock == 'information' && <ProfileInformationBlock t={t} user={user} /> }
                { activeBlock == 'edit' && <ProfileEditBlock t={t} user={user} error={error} setError={setError} /> }
                { activeBlock == 'subscription' && <ProfileSubscriptionBlock t={t} user={user} location={location} /> }
                { activeBlock == 'myusers' && <ProfileMyUsersBlock t={t} user={user} error={error} setError={setError} /> }
                { activeBlock == 'quizzes' && <ProfilePassedQuizBlock t={t} user={user} error={error} setError={setError} /> }
            </div>
        </div>
    </div>;
});

export default AccountPage;