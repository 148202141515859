/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Gistology_Data_API.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая занимается формированием запросов к серверной части проекта. Относится к части, которая занимается формированием запросов конкретных данных связанных с кейсами к серверной стороне. Gistology_Data_API.js
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import {$authHost, $host} from "../index";
import jwt_decode from "jwt-decode";

//-----------

//===========
//// begin [ Gistology ]

//===========
// begin [ Gistology_Categories ]

export const fetchGistology_Categories = async (search, language, ) => {
    const {data} = await $host.get('api/gistology/gistology_categories', {params: {
            search, language
        }})
    return data
}

export const fetchOneGistology_Categories = async (id) => {
    const {data} = await $host.get('api/gistology/gistology_categories/' + id)
    return data
}

// end [ Gistology_Categories ]
//===========

//-----------

//===========
// begin [ Gistology_Data ]

export const fetchGistology_Data = async (search, language, entities_id, gender_id, categories_id, page, limit) => {
    const {data} = await $host.get('api/gistology/gistology_data', {params: {
            search, language, entities_id, gender_id, categories_id, page, limit
        }})
    return data
}

export const fetchOneGistology_Data = async (id) => {
    const {data} = await $host.get('api/gistology/gistology_data/' + id)
    return data
}

// end [ Gistology_Data ]
//===========

//// end [ Gistology ]
////===========

//-----------