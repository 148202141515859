/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: News_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. Относится к части, которая содержит существенную часть проекта - страницы атласа. Страница с новостями сайта.
 *	@author: Манжос Геннадий Юрьевич, Горбас Александр Петрович, Белов Михаил Александрович
*/
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';

import './News_Page.css';

const Terms_Page = observer(() => {
    const navigate = useNavigate();
    const { t } = useTranslation();
	const [ md, setMD ] = useState(null);

	useEffect( () => {
		fetch('CHANGELOG.md').then( d => d.text().then( d => setMD(d) ) );
	}, [] );

	if (!md)
		return;

	return <div className='news_page'>
		<h2 className='news_page-title'>Новости</h2>
		<hr />
		<div className='news_page-content'>
			<Markdown>{md}</Markdown>
		</div>
	</div>;
});

export default Terms_Page;