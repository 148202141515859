/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Genetics_Categories_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "генетика". В этом файле находится содержимое страницы, которая выводится при переходе на страницу категории.
 *	@author: Манжос Геннадий Юрьевич, Белов Михаил Александрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../index';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Search_Bar from '../../components/Search_Bar';
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';
import '../../components/Categories_Page.css';
import InProcess from '../InProcess'

export const Genetics_EntriesPerPage = 6;

const Genetics_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const [pageData, setPageData] = useState({});
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Genetics_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user, user.search]);

    if (!pageData || !pageData?.subCategory)
        return;

    return <div className='page_content'>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/gen_icon.png' alt='page_title_icon'></img>{t('genetics_title')}
        </div>
        <div className='page_content_parent'>
            <div className='page_content_flex'>
                <SidebarCategoriesDisplay categories={pageData.subCategory} matchingCategories={pageData.search?.matches} />
            </div>
            <div className='page_content_rightpage_block'>
                {/* <InProcess /> */}
                <div className='Search_bar_block'>
                    <Search_Bar searchSucceeded={pageData.search?.succeeded} />
                </div>
                <div className='page_content_rightpage'>
                    <div className='page_content_title_info'>
                        {t('genetic_desc_1')}
                        <br></br>
                        <br></br>
                        {t('genetic_desc_2')}
                        <br></br>
                        <br></br>
                        {t('genetic_desc_3')}
                        <br></br>
                        <br></br>
                    </div>
                </div>
            </div>
        </div>
    </div>;
});

export default Genetics_Categories_Page;