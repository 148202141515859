/**
 *	(c) 2024 Приволжский Исследовательский Медицинский Университет
 *
 *	@file: Ultrasound_Data_EntriesTable_Page.js
 *  @description: Этот файл является частью клиентской стороны проекта. src Относится к части, которая содержит существенную часть проекта - страницы атласа. Категория "ультразвуковая диагностика". В этом файле находится содержимое страницы, которая выводится при переходе на страницу со списком кейсов, относящихся к конкретной категории.
 *	@author: Белов Михаил Александрович, Горбас Александр Петрович
*/
import React, { useContext, useEffect, useState } from 'react';
import { Card, Container } from "react-bootstrap";
import Ultrasound_Data_EntriesList from "../../components/Ultrasound/Ultrasound_Data_EntriesList";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import Pages from "../../components/Pages";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import Gender_Bar from '../../components/Gender_Bar';
import { Ultrasound_EntriesPerPage } from './Ultrasound_Categories_Page';

const Ultrasound_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const location = useLocation();
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, Ultrasound_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/ultrasound_icon.png' alt='page_title_icon'></img>{t('ultrasound_title')}
        </div>
        <Container>
            <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
            <div className='content_cases'>
                <div className='content_filters'>
                    <Gender_Bar onGenderChanged={(gender) => { user.gender = gender; user.page = 1; }} onAgeChanged={({ min, max }) => { user.ages = [min, max]; user.page = 1; }} />
                </div>
                <div className='content_body'>
                    <Ultrasound_Data_EntriesList pageData={pageData} />
                    <div className='content_Pages'>
                        <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
                    </div>
                </div>
            </div>
        </Container>;</>
});

export default Ultrasound_Data_EntriesTable_Page;